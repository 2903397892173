import {Language, Theme} from '@common/index';
import { useAppSelector, useLanguage, useThemeWatcher } from '@hooks/index';
import { AppRouter } from '@routes/index';
import { GlobalStyle } from '@styles/GlobalStyle';
import { themeObject } from '@styles/themes/ThemeVariables';
import { getLocale } from '@utils/index';
import { ConfigProvider } from 'antd';
import 'typeface-lato';
import 'typeface-montserrat';
import {MainLayout} from "@app/components";
import {useAppDispatch} from "@hooks/index";
import {detectAnomalies} from "@store/slices/AnomalyDetection/thunks";
import {useEffect, useState} from "react";

const App = () => {
  const { language } = useLanguage();
  const theme: Theme = useAppSelector((state) => state.theme.data);
    const dispatch = useAppDispatch();

    useThemeWatcher();

  return (
      <>
          <meta name="theme-color" content={themeObject[theme].primaryColor}/>
          <GlobalStyle/>
          <ConfigProvider locale={getLocale(language)}>
              <MainLayout>
                  <AppRouter/>
              </MainLayout>
          </ConfigProvider>
      </>
  );
};

export default App;
