import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import i18n from '@app/i18n'; // Ensure this is correctly configured
import * as S from './ChangeLanguage.style';
import { useAppDispatch } from '@app/hooks';
import { setLanguage } from '@store/slices/UserContent/slice';
import { useTranslation } from "react-i18next";

const { Option } = Select;

const LanguageSelector: React.FC = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const [selectedLanguage, setSelectedLanguage] = useState<string>(() => {
        const storedLanguage = localStorage.getItem('lng');
        return storedLanguage || i18n.language || 'en'; // Default to 'en' if no stored value
    });

    const handleLanguageChange = async (language: string) => {
        await i18n.changeLanguage(language); // Update the i18n language
        setSelectedLanguage(language); // Update component state
        localStorage.setItem('lng', language); // Persist language selection
        dispatch(setLanguage(language)); // Dispatch action to store
    };

    // Set language on mount
    useEffect(() => {
        i18n.changeLanguage(selectedLanguage); // Change the language immediately on mount
    }, []);

    return (
        <S.Container>
            <S.Title>{t("pages.account.changeLanguage.selectLang")}</S.Title>
            <Form form={form}>
                <S.Label htmlFor="language">{t("pages.account.changeLanguage.language")}</S.Label>
                <S.InputContainer>
                    <Form.Item name="language" initialValue={selectedLanguage}>
                        <Select
                            id="language"
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                            placeholder={t("pages.account.changeLanguage.selectLang")}
                        >
                            <Option value="en">{t("pages.account.changeLanguage.english")}</Option>
                            <Option value="tr">{t("pages.account.changeLanguage.turkish")}</Option>
                            <Option value="de">{t("pages.account.changeLanguage.german")}</Option>
                            {/* Add more language options as needed */}
                        </Select>
                    </Form.Item>
                </S.InputContainer>
            </Form>
        </S.Container>
    );
};

export default LanguageSelector;
