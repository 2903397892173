import {DEFAULT_FILTERS, FetchHistogramForm, FilterType, IMapSettings, Theme, TrackHistogram} from '@app/common';
import {Space} from '@app/components';
import {useAppDispatch, useAppSelector, useAxios, useResponsive} from '@app/hooks';
import {Dates, persistMapSettingsDataCurrent} from '@app/services';
import {generateFilter, generateFilterItem} from '@app/utils';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as S from './GlobalTimeFilter.style';
import {Col, Row} from "antd";
import moment from 'moment';
import {EChartsOption} from "echarts";
import {setTimeRangeFilter} from "@store/slices/Filters/slice";
import {setIsDataCurrent} from "@store/slices/MapSettings/slice";
import { themeObject } from '@app/styles/themes/ThemeVariables';
import {lightColorsTheme} from "@styles/themes/light/LightTheme";
const GlobalTimeFilter: React.FC = () => {
    const today = moment();
    const oneYearAgo = moment(today).subtract(1, 'years');
    const days = moment().diff(oneYearAgo, 'days');
    const theme: Theme = useAppSelector((state) => state.theme.data);

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {mobileOnly} = useResponsive();

    const filters = useAppSelector((state) => state.filters);
    const isDataCurrent: boolean = useAppSelector((state) => state.mapSettings.isDataCurrent);

    const HISTOGRAM_ENDPOINT = `${process.env.REACT_APP_BASE_PATH}/histogram`;

    const {data, triggerRequest, error} = useAxios<TrackHistogram[]>({
        url: HISTOGRAM_ENDPOINT,
        method: 'post',
    });
    const mapSettings: IMapSettings = useAppSelector((state) => state.mapSettings);
    const timeFilter = useAppSelector((state) => state.filters.timeRange);
    const [updateTrigger,setUpdateTrigger] = useState<boolean>(true); //trigger for data update
    const [histogramData, setHistogramData] = useState<TrackHistogram[]>([]);
    const [timeRange, setTimeRange] = useState<[moment.Moment, moment.Moment]>([moment(timeFilter[0]), moment(timeFilter[1])]);
    const [sliderValue, setSliderValue] = useState<[number, number]>([moment(timeFilter[0]).diff(oneYearAgo, 'days'), days]);

    const handleSliderChange = (value: [number, number]) => {

        const startDate = moment(oneYearAgo).add(value[0], 'days');
        const endDate = moment(today).subtract(days - value[1], 'days');
        setSliderValue(value);
        setTimeRange([startDate, endDate]);
    }

    const handleSliderAfterChange = (value: [number, number]) => {
        const startDate = moment(oneYearAgo).add(value[0], 'days');
        const endDate = moment(today).subtract(days - value[1], 'days');
        dispatch(setTimeRangeFilter([startDate.toISOString(), endDate.toISOString()]));
        dispatch(setIsDataCurrent(false));
        setUpdateTrigger(prev => !prev);
        persistMapSettingsDataCurrent(false);
    }
    
    const handleReset = () => {
        const startDate = DEFAULT_FILTERS.timeRange[0];
        setSliderValue([moment(startDate).diff(oneYearAgo, 'days'), sliderValue[1]]);
        setTimeRange([moment(startDate), timeRange[1]]);
        // Mark data as current, skipping any conditional checks, to ensure it updates even if already marked as current.
        dispatch(setIsDataCurrent(true));

        // Trigger an immediate data update by toggling the state variable, forcing a re-fetch.
        setUpdateTrigger(prev => !prev);
    }

    useEffect(() => {
        if (data && Array.isArray(data) && data.length > 0) {
            setHistogramData(data);
        }
    }, [data]);

    useEffect(() => {
        if (!filters.mapBounds) {
            return;
        }

        const triggerDispatchHistogram = () => {
            const fetchHistogramForm: FetchHistogramForm = {
                last: '1y',
                geoBox: [filters.mapBounds.northWest, filters.mapBounds.southEast],
            };

            const filter = generateFilter([
                generateFilterItem(FilterType.GEOBOX, fetchHistogramForm.geoBox),
            ]);

            triggerRequest({
                data: filter,
            });

        };
        if (filters.mapBounds.northWest && filters.mapBounds.southEast)
            triggerDispatchHistogram();

        let intervalTime = error ? 3000 : 600000; // 3 seconds or 10 minutes in milliseconds
        let interval = setInterval(() => {
            triggerDispatchHistogram();
        }, intervalTime); // 10 minutes in milliseconds
        return () => clearInterval(interval);
    }, [error, filters.mapBounds]);

    useEffect(() => {
        const updateData = () => {
            if (isDataCurrent) {
                const now = moment();
                setTimeRange([timeRange[0], now]);
                setSliderValue([sliderValue[0], now.diff(oneYearAgo, 'days')]);
                dispatch(setTimeRangeFilter([timeRange[0].toISOString(), now.toISOString()]));
            }
        }
        updateData();

        const interval = setInterval(updateData, 60000);

        return () => clearInterval(interval);
    }, [updateTrigger]);

    function getDataColor(index: number) {
        if (index < sliderValue[0]) {
            return themeObject[theme].chartBarBackgroundColor;

        } else if (index > sliderValue[1]) {
            return themeObject[theme].chartBarBackgroundColor;

        } else {
            return lightColorsTheme.primaryColor;

        }
    }

    const option: EChartsOption = {
        maintainAspectRatio: true,
        height: '25px',
        xAxis: {
            data: histogramData.map((h) => Dates.convertStringToDate(h.date as string).format('LL')),
            splitLine: {show: false},
            axisTick: {show: false},
        },
        yAxis: {
            show: false,
        },
        grid: {
            top: 0,
            bottom: '10%',
            right: 0,
            left: 0,
        },
        series: [
            {
                name: t('common.count'),
                type: 'bar',
                data: histogramData.map((h, index) => ({
                    value: h.count,
                    itemStyle: {
                        color: getDataColor(index),
                    },
                })),
            },
        ],
    };

    const handleRangeChange = (dates: any, _: [string, string]) => {
        if (dates) {
            setTimeRange(dates);
            const startDate = dates[0].diff(oneYearAgo, 'days');
            const endDate = dates[1].diff(oneYearAgo, 'days');
            setSliderValue([startDate, endDate]);
            dispatch(setTimeRangeFilter([dates[0].toISOString(), dates[1].toISOString()]));
            dispatch(setIsDataCurrent(false));
            setUpdateTrigger(prev => !prev);
            persistMapSettingsDataCurrent(false);
        } else {
            setTimeRange([oneYearAgo, today]);
            setSliderValue([0, days]);
            dispatch(setTimeRangeFilter([oneYearAgo.toISOString(), today.toISOString()]));
        }
    }

    return (
        <S.GlobalFilterCardContainer>
            <S.GlobalFilterCard padding={[5, 15]} isSatelliteView={mapSettings.isSatelliteView}>
                <Row>
                    <Space direction={"vertical"} size={[0, 1]}>
                        <Col>
                            <S.GlobalTimeFilterChart option={option}/>
                        </Col>
                        <Col>
                            <S.GlobalTimeFilterSlider range value={sliderValue} onAfterChange={handleSliderAfterChange}
                                                      onChange={handleSliderChange}
                                                      tooltip={{open: false}}
                                                      min={0} max={days}/>
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'center'}}>
                            <S.GlobalTimeFilterRangePicker size={"small"} value={timeRange} onChange={handleRangeChange}
                                                           disabledDate={(current) => {
                                                               return current &&
                                                                   (current > moment().endOf('day') || current < moment()
                                                                       .subtract(1, 'year').startOf('day'));
                                                           }}
                            />
                            <S.ResetButton onClick={handleReset} size={"small"}>{t('common.reset')}</S.ResetButton>
                            <S.CurrentDataButton onClick={() => {
                                dispatch(setIsDataCurrent(true));
                                setUpdateTrigger(prev => !prev);
                                persistMapSettingsDataCurrent(true);
                            }} size={"small"}>{t('common.live')}</S.CurrentDataButton>
                        </Col>
                    </Space>
                </Row>
            </S.GlobalFilterCard>
        </S.GlobalFilterCardContainer>
    );
};

export default GlobalTimeFilter;
