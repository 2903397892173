import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {MergedAnalyticData} from "@app/common";

// Define the initial state type
interface MergedDestinationState {
    destinations: MergedAnalyticData[];
}

// Initial state
const initialState: MergedDestinationState = {
    destinations: [],
};

// Create the slice
const mergedDestinationSlice = createSlice({
    name: 'mergedDestination',
    initialState,
    reducers: {
        // Action to set destinations
        setMergeDestination(state, action: PayloadAction<MergedAnalyticData[]>) {
            state.destinations = action.payload; // Set the destinations to the provided array
        },
    },
});

// Export actions
export const {
    setMergeDestination
} = mergedDestinationSlice.actions;

// Export the reducer
export default mergedDestinationSlice.reducer;