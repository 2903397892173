import React, {useEffect, useState} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';
import {clearVesselMmsiList, setIsOn, setVesselMmsiList} from '@store/slices/AllTracks/VesselSlice';
import {Button, Drawer, message, Modal, Popover} from 'antd';
import FleetModeSwitch from './FleetModeSwitch/FleetModeSwitch';
import FleetList from './FleetList/FleetList';
import AddFleetModal from './AddFleetModal/AddFleetModal';
import EditFleetModal from './EditFleetModal/EditFleetModal';
import FleetVesselsModal from './FleetVesselsModal/FleetVesselsModal';
import LimitReachedModal from './LimitReachedModal/LimitReachedModal';
import {Icon} from '@iconify/react';
import {useAppDispatch} from '@app/hooks';
import {
    deleteFleet,
    deleteVesselFromFleet,
    fetchFleetsByEmail,
    fetchFleetVesselsByName,
    updateFleet
} from "@store/slices/UserContent/thunks";
import {useAuth} from "@app/context/AuthContext";
import {useTranslation} from "react-i18next";


interface EmptyModalProps {
    visible: boolean;
    onClose: () => void;
    fleets: any[];
    setFleets: (fleets: any[]) => void;
    fleetUsage: number;
    handleFleetUsage: () => void;
    handleAddFleet: (name: string, color: string) => void;
    setFleetUsage: (usage: number) => void;
    setIsFleetMode;
    setAllVesselMMSIs;
}

const FleetModal: React.FC<EmptyModalProps> = ({
                                                   visible,
                                                   onClose,
                                                   fleets,
                                                   setFleets,
                                                   fleetUsage,
                                                   handleAddFleet,
                                                   setFleetUsage,
                                                   setIsFleetMode
                                               }) => {
    const { t } = useTranslation();
    const [isOnn, setIsOnn] = useState<boolean>(false);
    const [newFleetName, setNewFleetName] = useState<string>('');
    const [newFleetColor, setNewFleetColor] = useState<string>('#000000');
    const [isLimitReachedModalVisible, setIsLimitReachedModalVisible] = useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [isAddFleetModalVisible, setIsAddFleetModalVisible] = useState<boolean>(false);
    const [isFleetVesselsModalVisible, setIsFleetVesselsModalVisible] = useState<boolean>(false);
    const [fleetVesselsMmsi, setFleetVesselsMmsi] = useState<string[]>([]);
    const [fleetVesselLoading, setFleetVesselLoading] = useState<boolean>(false);
    const [editedFleetName, setEditedFleetName] = useState<string>('');
    const [editedFleetColor, setEditedFleetColor] = useState<string>('#000000');
    const [editingFleetIndex, setEditingFleetIndex] = useState<number | null>(null);
    const [selectedFleetName, setSelectedFleetName] = useState<string>('');

    const dispatch = useAppDispatch();
    const {currentUser} = useAuth();
    const userEmail = currentUser?.email ? currentUser.email : "";

    useEffect(() => {
        if (isOnn) {
            fetchAllFleetNamesAndVessels();

            dispatch(setIsOn(true));

        } else {
            dispatch(clearVesselMmsiList());
            dispatch(setIsOn(false));
        }
    }, [isOnn, dispatch]);

    const fetchAllFleetNamesAndVessels = async () => {
        try {

            dispatch(fetchFleetsByEmail({email: userEmail}))
                .unwrap()
                .then(data => {
                    const fleetNames = data.map((fleet: any) => fleet.name);
                    fetchAllVesselMmsi(fleetNames);
                })



        } catch (error) {
            console.error(t("pages.home.popover.modal.errName"), error);
        }
    };

    const fetchAllVesselMmsi = async (fleetNames: string[]) => {
        const allMmsi: string[] = [];

        try {
            for (const fleetName of fleetNames) {
                const resultAction = await dispatch(fetchFleetVesselsByName({email: userEmail,  fleetName }));

                const vesselMMSIs = unwrapResult(resultAction).map((vessel: { mmsi: string }) => vessel.mmsi);
                allMmsi.push(...vesselMMSIs);
            }


            dispatch(setVesselMmsiList(allMmsi));
            setIsFleetMode(true);
        } catch (error) {
            console.error(t("pages.home.popover.modal.errMMSI"), error);
        }
    };

    const handleAdd = () => {
        if (fleetUsage >= 5) {
            setIsLimitReachedModalVisible(true);
            return;
        }
        setIsAddFleetModalVisible(true);
    };

    const handleAddFleetConfirm = () => {
        if (newFleetName.trim()) {
            handleAddFleet(newFleetName, newFleetColor);
            setNewFleetName('');
            setNewFleetColor('#000000');
            setIsAddFleetModalVisible(false);
        }
    };

    const handleDeleteFleet = async (index: number) => {
        const fleetToDelete = fleets[index];

        Modal.confirm({
            title: t("pages.home.popover.modal.modalTitleFleet"),
            content: `Fleet Name: ${fleetToDelete.name}`,
            okText: t("common.yes"),
            okType: 'danger',
            cancelText: t("common.no"),
            onOk: async () => {
                try {
                    await dispatch(deleteFleet({email: userEmail, fleetName: fleetToDelete.name }))
                        .unwrap();

                        const updatedFleets = [...fleets];
                        updatedFleets.splice(index, 1);
                        setFleetUsage(fleetUsage - 1);
                        setFleets(updatedFleets);
                        message.success(t("pages.home.popover.modal.msgDelFleet"));
                    }
                 catch (error) {
                    console.error(t("pages.home.popover.modal.errDelFleet"), error);
                }
            }
        });
    };

    const handleEditFleet = (index: number) => {
        const fleetToEdit = fleets[index];
        setEditedFleetName(fleetToEdit.name);
        setEditedFleetColor(fleetToEdit.color);
        setEditingFleetIndex(index);
        setIsEditModalVisible(true);
    };

    const handleUpdateFleet = async () => {
        if (editingFleetIndex !== null) {
            const fleetToEdit = fleets[editingFleetIndex];
            const updatedFleet = {
                name: editedFleetName,
                color: editedFleetColor,
            };

            try {
                dispatch(updateFleet({
                    email: userEmail,
                    fleetName: fleetToEdit.name,
                    fleetData: updatedFleet
                })).unwrap()
                    .then(()=> {
                        const updatedFleets = [...fleets];
                        updatedFleets[editingFleetIndex] = { ...fleetToEdit, ...updatedFleet };

                        setFleets(updatedFleets);

                        setIsEditModalVisible(false);
                        message.success(t("pages.home.popover.modal.msgUpdate"));
                    })


            } catch (error) {
                console.error(t("pages.home.popover.modal.errUpdate"), error);
            }
        }
    };



    const handleFetchFleetVessels = async (fleetName: string) => {
        setFleetVesselLoading(true);
        setSelectedFleetName(fleetName);


        try {
            const resultAction = await dispatch(fetchFleetVesselsByName({email: userEmail, fleetName: fleetName }));

            if (fetchFleetVesselsByName.fulfilled.match(resultAction)) {
                const vessels = resultAction.payload;


                const vesselMMSIss = vessels.map((vessel: { mmsi: string }) => vessel.mmsi);
                setFleetVesselsMmsi(vesselMMSIss);
                setIsFleetVesselsModalVisible(true);
            } else {
                console.error(t("pages.home.popover.modal.failFetch"), resultAction.payload);
            }
        } catch (error) {
            console.error(t("pages.home.popover.modal.errFetch"), error);
        } finally {
            setFleetVesselLoading(false);
        }
    };
    const handleDeleteVessel = async (mmsi: string) => {
        Modal.confirm({
            title: t("pages.home.popover.modal.modalTitle"),
            content: `MMSI: ${mmsi}`,
            okText: t("common.yes"),
            okType: 'danger',
            cancelText: t("common.no"),
            onOk: async () => {
                setFleetVesselLoading(true);

                try {
                    await dispatch(deleteVesselFromFleet({email:userEmail, fleetName: selectedFleetName, vesselMmsi: mmsi })).unwrap();



                        const updatedFleetVessels = fleetVesselsMmsi.filter(vessel => vessel !== mmsi);
                        setFleetVesselsMmsi(updatedFleetVessels);
                        message.success(t("pages.home.popover.modal.msgDelete"));

                } catch (error) {
                    console.error(t("pages.home.popover.modal.errDelete"), error);
                } finally {
                    setFleetVesselLoading(false);
                }
            }
        });
    };

    function handlerIsOn(){
        setIsOnn(!isOnn);
    }
    return (
        <>
            <Drawer
                title={t("pages.home.popover.modal.fleetmng")}
                placement="left"
                onClose={onClose}
                visible={visible}
                width={400}
                footer={
                    <Button key={t("common.close")} onClick={onClose}>
                        {t("common.close")}
                    </Button>
                }
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <FleetModeSwitch label={t("pages.home.popover.modal.fleetMode")} checked={isOnn} onChange={handlerIsOn} />
                    <Popover
                        content={t("pages.home.popover.modal.addNew")}
                        trigger="hover"
                        placement="topRight"
                    >
                        <Button
                            type="primary"
                            onClick={handleAdd}
                            disabled={fleetUsage >= 5}
                            style={{ marginLeft: 'auto' }}
                        >
                            <Icon icon="material-symbols:add" />
                        </Button>
                    </Popover>
                </div>
                <FleetList
                    fleets={fleets}
                    handleFetchFleetVessels={handleFetchFleetVessels}
                    handleEditFleet={handleEditFleet}
                    handleDeleteFleet={handleDeleteFleet}
                />
            </Drawer>


            <AddFleetModal
                visible={isAddFleetModalVisible}
                newFleetName={newFleetName}
                setNewFleetName={setNewFleetName}
                newFleetColor={newFleetColor}
                setNewFleetColor={setNewFleetColor}
                handleAddFleetConfirm={handleAddFleetConfirm}
                onCancel={() => setIsAddFleetModalVisible(false)}
            />

            <EditFleetModal
                visible={isEditModalVisible}
                editedFleetName={editedFleetName}
                setEditedFleetName={setEditedFleetName}
                editedFleetColor={editedFleetColor}
                setEditedFleetColor={setEditedFleetColor}
                handleUpdateFleet={handleUpdateFleet}
                onCancel={() => setIsEditModalVisible(false)}
            />

            <FleetVesselsModal
                visible={isFleetVesselsModalVisible}
                fleetVesselLoading={fleetVesselLoading}
                fleetVesselsMmsi={fleetVesselsMmsi}
                handleDeleteVessel={handleDeleteVessel}
                onCancel={() => setIsFleetVesselsModalVisible(false)}
            />

            <LimitReachedModal
                visible={isLimitReachedModalVisible}
                onOk={() => setIsLimitReachedModalVisible(false)}
                onCancel={() => setIsLimitReachedModalVisible(false)}
            />
        </>
    );
};

export default FleetModal;
