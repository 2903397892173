import {CloseOutlined, FilterOutlined, FilterFilled} from '@ant-design/icons';
import {Countries, IMapFilters, IMapSettings, SpeedFilter, Track} from '@app/common';
import {H3, Space} from '@components/index';
import {useAppDispatch, useAppSelector} from '@app/hooks';
import {Dates} from '@app/services';
import {setSelectedMapTrackId} from '@store/slices/Tracks/slice';
import {Col, Row, Tooltip, Button, Modal, Select, message} from 'antd';
import L from 'leaflet';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as S from './VesselCard.style';
import {fetchFleetsByEmail, addVesselToFleet, getFleetByName} from "@store/slices/UserContent/thunks";
import {useAuth} from "@app/context/AuthContext";
import {NavigationalStatus} from "@common/enums";
import {setMergeDestination } from "@app/store/slices/MergedDataDestination/slice";

import {
  setFlags,
  setVesselTypes,
  setDestinations,
  setSpeeds,
  setNavigationalStatuses,
} from "@store/slices/SelectedFilters/slice";

const {Option} = Select;

interface IVesselCardProps {
  track: Track;
  direction: 'vertical' | 'horizontal';
}

const VesselCard: React.FC<IVesselCardProps> = ({track, direction}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const mapSettings: IMapSettings = useAppSelector((state) => state.mapSettings);
  const selectedFields = useAppSelector((state) => state.selectedFilters);
  const mergeDestinations = useAppSelector((state) => state.mergeDestinations);
  // Mapping field names to their corresponding action creators
  const actionMap = {
    flags: setFlags,
    vesselTypes: setVesselTypes,
    destinations: setDestinations,
    speeds: setSpeeds,
    navigationalStatuses: setNavigationalStatuses,
  };
  const IMAGE_SERVER_PATH = process.env.REACT_APP_IMAGE_SERVER_PATH;
  const [selectedVessel, setSelectedVessel] = useState<Track | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fleets, setFleets] = useState<any[]>([]);
  const [selectedFleet, setSelectedFleet] = useState<string | null>(null);
  const verticalCardRef = useRef<HTMLDivElement>(null);
  const {currentUser} = useAuth();
  const userEmail = currentUser?.email ? currentUser.email : "";
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: boolean }>({});
  //const filters: IMapFilters = useAppSelector((state) => state.filters);

  useEffect(() => {
    if (verticalCardRef.current) {
      const cardElement = verticalCardRef.current;
      L.DomEvent.disableClickPropagation(cardElement);
      L.DomEvent.disableScrollPropagation(cardElement);
    }
  }, []);

  useEffect(() => {
    const newActiveSelectedFields: { [key: string]: boolean } = {};

    // Loop through each field in selectedFields to determine if it's active
    Object.keys(selectedFields).forEach((field) => {
      newActiveSelectedFields[field] = selectedFields[field]?.length > 0; // true if there's at least one item
    });

    setActiveFilters(newActiveSelectedFields); // Update activeSelectedFields state
  }, [selectedFields]);

  const speedFieldExtractor = (d: string) => {
    const speedVal:number = parseInt(d);

    if(speedVal<5){
      return ['*-5.0']
    }else if(speedVal<10){
      return ['5.0-10.0']
    }else{
      return ['10.0-*']
    }
    return ['*-5.0'];
  };

  const destinationFieldExtractor = (d: string): string[] => {
    // Find the destination with the matching key.name
    const destination = mergeDestinations.destinations.find(destination => destination.key.name === d);

    // If a matching destination is found, return its values; otherwise, return an empty array
    return destination ? Array.from(destination.key.values) : [];
  };


  // Function to handle filter icon click
  const toggleFilter = (field: string, value: string | number,selectedFieldsExtractor?:  ((d: any) => any) | undefined) => {
    // Get the current filter values for the field
    let copyItems = [...selectedFields[field]] || [];

    const valuesToToggle = selectedFieldsExtractor ? selectedFieldsExtractor(value) : [value];

    // Iterate through valuesToToggle to check their existence
    valuesToToggle.forEach((val) => {
      const valueExists = copyItems.includes(val);

      // If the value exists, remove it from the copyItems
      if (valueExists) {
        copyItems = copyItems.filter(item => item !== val);
      } else {
        // If the value does not exist, add it to the copyItems
        copyItems.push(val);
      }
    });
    console.log("copyItems",copyItems)

    const actionCreator = actionMap[field]
    dispatch(
        actionCreator(copyItems)
    );
  };

  // Function to render a clickable filter icon
  const renderFilterIcon = (field: string, value: string | number,selectedFieldsExtractor?:  ((d: any) => any) | undefined) => {
    const isActive = activeFilters[field];
    return (
        <span onClick={() => toggleFilter(field,value,selectedFieldsExtractor)}>
        {isActive ? <FilterFilled /> : <FilterOutlined />}
      </span>
    );
  };

  const handleAddToFleet = async (track: Track) => {
    setSelectedVessel(track);
    try {
      dispatch(fetchFleetsByEmail({email: userEmail}))
          .unwrap()
          .then((data) => {
            setFleets(data);
            setIsModalVisible(true);
          })

    } catch (error) {
      console.error('Error fetching fleets:', error);
      message.error('Error fetching fleets');
    }
  };

  const handleAddVesselToFleet = async () => {
    if (selectedVessel && selectedFleet) {
      try {
        const fleetData = await dispatch(getFleetByName({email: userEmail, fleetName: selectedFleet})).unwrap();
        const vesselExists = fleetData.vessels.some((vessel: {
          mmsi: string
        }) => vessel.mmsi === selectedVessel.mmsi);
        if (vesselExists) {
          message.warning('Vessel is already added to the selected fleet');
        } else {

          dispatch(addVesselToFleet({
            email: userEmail,
            fleetName: selectedFleet,
            vesselMmsi: selectedVessel.mmsi
          }));
          message.success('Vessel added to fleet successfully');
        }
      } catch (error) {
        message.error('Error adding vessel to fleet');
      }
    }
    setIsModalVisible(false);
  };


  const generateInfoItem = (title: string, value: string | number, fieldKey: string| null = null, selectedFieldsExtractor?:  ((d: any) => any) | undefined) => {
    return (
        <Row justify="space-between" align="middle">
          <S.InformationText>{title}</S.InformationText>
          <Space>
            <S.InformationText>{value}</S.InformationText>
            {fieldKey && value !== undefined && value !== null &&
            (typeof value !== 'string' || value.length > 0) ? (
                renderFilterIcon(fieldKey, value, selectedFieldsExtractor)
            ) : (
                <div style={{ width: '16px' }} />
            )}
          </Space>
        </Row>
    );
  };

  const getVesselFlag = (countryCode: string | undefined) => {

    const unknownFlagOutput = (
        <Tooltip title={t('common.unknown')}>
          <S.UnknownFlag/>
        </Tooltip>
    );

    if (!countryCode) {
      return unknownFlagOutput;
    }

    const countryName = Countries[countryCode as keyof typeof Countries];

    return countryName ? (
        <Space>
          <Tooltip title={countryName}>
            <S.CountryFlag svg countryCode={countryCode}/>
          </Tooltip>
          {renderFilterIcon("flags", countryName)}
        </Space>
    ) : (
        unknownFlagOutput
    );
  };

  const onCancelHandler = () => {
    dispatch(setSelectedMapTrackId(undefined));
  };

  const TrackInfoItems = () => {
    return (
        <Col>
          {generateInfoItem(t('pages.home.mmsi'), track.mmsi)}
          {generateInfoItem(t('pages.home.imo'), track.imo)}
          {generateInfoItem(t('pages.home.callSign'), track.callsign)}
          {generateInfoItem(t('pages.home.type'), track.vesselType, 'vesselTypes')}
          {generateInfoItem(t('pages.home.speed'), track.speed, 'speeds',speedFieldExtractor)}
          {generateInfoItem(t('pages.home.course'), track.course)}
          {/* Generate an info item for the vessel's Navigational Status,
                converting the numeric status to its corresponding label */}
          {generateInfoItem(t('pages.home.navigationalStatus'), NavigationalStatus[track.navigationalStatus], 'navigationalStatuses')}
          {generateInfoItem(t('pages.home.destination'), track.destination, 'destinations',destinationFieldExtractor)}
        </Col>
    )
  };

  const renderVertical = () => {
    return (
        <S.VerticalCardContainer ref={verticalCardRef}>
          {/* Styled component for the card itself, with conditional opacity based on isSatelliteView */}
          <S.VerticalCard padding={[18, 18]}  isSatelliteView={mapSettings.isSatelliteView}>
            <Space direction="vertical">
              <S.VerticalCardVesselImageContainer>
                <S.VerticalCardVesselImage src={`${IMAGE_SERVER_PATH}/vessel?mmsi=${track.mmsi}`}/>
                <S.VerticalCardCloseButtonContainer>
                  <S.CloseButton icon={<CloseOutlined/>} onClick={onCancelHandler}/>
                </S.VerticalCardCloseButtonContainer>
              </S.VerticalCardVesselImageContainer>
              <Col>
                <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                  <H3 style={{ flex: 1, textAlign: 'center' }}>{track.name}</H3>
                  <div style={{ marginLeft: 'auto' }}>{getVesselFlag(track.flag)}</div>
                </Row>
                <Row justify={'center'}>
                  <S.ItalicText>{Dates.getDate(track.timestamp).format('LL HH:mm:ss')}</S.ItalicText>
                </Row>
              </Col>
              <TrackInfoItems />
              <Col>
                <Button type="primary" onClick={() => handleAddToFleet(track)}>
                  {t('pages.home.addToFleet')}
                </Button>
              </Col>
            </Space>
            <Modal
                title={t('pages.home.addVesselToFleet')}
                visible={isModalVisible}
                onOk={handleAddVesselToFleet}
                onCancel={() => setIsModalVisible(false)}
            >
              <p>Select a fleet to add the vessel to:</p>
              <Select
                  style={{width: '100%'}}
                  placeholder={t('pages.home.slcFleet')}
                  onChange={(value) => setSelectedFleet(value)}
              >
                {fleets.map((fleet) => (
                    <Option key={fleet.name} value={fleet.name}>
                      {fleet.name}
                    </Option>
                ))}
              </Select>
            </Modal>
          </S.VerticalCard>
        </S.VerticalCardContainer>
    );
  };

  const renderHorizontal = () => {
    return (
        <S.HorizontalCard padding={[18, 18]}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <S.HorizontalCardVesselImageContainer>
                <S.HorizontalCardVesselImage src={`${IMAGE_SERVER_PATH}/vessel?mmsi=${track.mmsi}`}/>
              </S.HorizontalCardVesselImageContainer>
            </Col>
            <Col span={12}>
              <Space direction="vertical">
                <Col>
                  <Row justify="space-between" align="middle">
                    <Space>
                      {getVesselFlag(track.flag)}
                      <H3>{track.name}</H3>
                    </Space>
                    <S.CloseButton icon={<CloseOutlined/>} onClick={onCancelHandler}/>
                  </Row>

                  <S.ItalicText>{Dates.getDate(track.timestamp).format('LL HH:mm:ss')}</S.ItalicText>
                </Col>

                <Row>
                  <TrackInfoItems />
                </Row>
                <Col>
                  <Button type="primary" onClick={() => handleAddToFleet(track)}>
                    {t('pages.home.addToFleet')}
                  </Button>
                </Col>
              </Space>
            </Col>
          </Row>
          <Modal
              title={t('pages.home.addVesselToFleet')}
              visible={isModalVisible}
              onOk={handleAddVesselToFleet}
              onCancel={() => setIsModalVisible(false)}
          >
            <p>Select a fleet to add the vessel to:</p>
            <Select
                style={{width: '100%'}}
                placeholder={t('pages.home.slcFleet')}
                onChange={(value) => setSelectedFleet(value)}
            >
              {fleets.map((fleet) => (
                  <Option key={fleet.name} value={fleet.name}>
                    {fleet.name}
                  </Option>
              ))}
            </Select>
          </Modal>
        </S.HorizontalCard>
    );
  };

  return direction === 'vertical' ? renderVertical() : renderHorizontal();
};

export default VesselCard;