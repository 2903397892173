import { Modal, Select } from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';

const { Option } = Select;

const AddToFleetModal = ({ isModalVisible, setIsModalVisible, fleets, handleAddVesselToFleet, setSelectedFleet }) => {

    const { t } = useTranslation();
    return (
        <Modal
            title={t("pages.account.fleetAdd.addVesselToFleet")}
            visible={isModalVisible}
            onOk={handleAddVesselToFleet}
            onCancel={() => setIsModalVisible(false)}
        >
            <p>Select a fleet to add the vessel to:</p>
            <Select
                style={{ width: '100%' }}
                placeholder={t("pages.home.slcFleet")}
                onChange={(value) => setSelectedFleet(value)}
            >
                {fleets.map((fleet) => (
                    <Option key={fleet.name} value={fleet.name}>
                        {fleet.name}
                    </Option>
                ))}
            </Select>
        </Modal>
    );
};

export default AddToFleetModal;
