import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import * as process from 'process';




const trackTable = process.env.REACT_APP_TRACK_SERVICE
export const fetchVesselsData = createAsyncThunk(
    'vessels/fetchVesselsData',
    async (filters: any, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${trackTable}/vessels`, filters);  // Assuming API is hosted at the same domain
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || 'Failed to fetch vessels data');
            } else {
                return rejectWithValue('An unexpected error occurred');
            }
        }
    }
);
