import { NoData } from '@app/components';
import { themeObject } from '@app/styles/themes/ThemeVariables';
import { generateFilter, generateFilterItem } from '@app/utils';
import {
  AnalyticData,
  MergedAnalyticData,
  ChartType,
  FilterType,
  IMapFilters,
  Theme,
  TrackAnalyticQueryFields,
} from '@common/index';
import {NavigationalStatus} from '@common/enums';
import {getRealDestination} from "@common/destinationLocode";
import { useAppSelector, useAxios } from '@hooks/index';
import React, {SetStateAction, useEffect, useRef, useState, useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './AnalyticDataChart.style';
import moment from "moment";
import { ECharts } from 'echarts';
import { useAppDispatch } from '@app/hooks';
import { setFilters } from '@app/store/slices/Filters/slice';
import {setMergeDestination} from "@app/store/slices/MergedDataDestination/slice";
import {
  setFlags,
  setVesselTypes,
  setDestinations,
  setSpeeds,
  setNavigationalStatuses,
} from "@store/slices/SelectedFilters/slice";


interface IAnalyticDataChartProps {
  type: ChartType;
  queryBaseUrl?: string;
  trackQueryField: TrackAnalyticQueryFields;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  yAxisExtractor?: (d: any) => any;
  sortDataEnabled?: boolean;
  fieldFilterName : string;
  selectedFieldsExtractor?: (d:string) => string;

}

const AnalyticDataChart: React.FC<IAnalyticDataChartProps> = ({
                                                                type,
                                                                queryBaseUrl,
                                                                trackQueryField,
                                                                yAxisExtractor,
                                                                selectedFieldsExtractor,
                                                                fieldFilterName,
                                                                setLoading,
                                                                sortDataEnabled= true,
                                                              }) => {
  const { t } = useTranslation();

  const MAXIMUM_DATA_LIMIT = 10;
  const MINIMUM_DATA_LIMIT = 5;

  const [isMoreDataOpened, setIsMoreDataOpened] = useState<boolean>(false);
  //const [selectedFields, setSelectedFields] = useState<string[]>([]);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const theme: Theme = useAppSelector((state) => state.theme.data);
  const filters: IMapFilters = useAppSelector((state) => state.filters);
  const selectedFields = useAppSelector((state) => state.selectedFilters);
  const mapBounds = useAppSelector((state) => state.filters.mapBounds);

  // Mapping field names to their corresponding action creators
  const actionMap = {
    flags: setFlags,
    vesselTypes: setVesselTypes,
    destinations: setDestinations,
    speeds: setSpeeds,
    navigationalStatuses: setNavigationalStatuses,
  };

  const BASE_PATH = process.env.REACT_APP_BASE_PATH;
  const ANALYTIC_COUNT_ENDPOINT = `${BASE_PATH}/count`;

  const queryURL = queryBaseUrl ? `${queryBaseUrl}` : `${ANALYTIC_COUNT_ENDPOINT}`;

  const {
    data,
    isLoading,
    error,
    triggerRequest: fetchChartData,
  } = useAxios<AnalyticData[]>({
    url: queryURL,
    method: 'post',
  });


  /**
   * Merges duplicate entries in the provided data based on similar keys.
   * The function removes spaces and standardizes keys to identify duplicates.
   * When duplicates are found, it aggregates their counts and keeps a record of the original keys.
   *
   * @param data - The array of analytic data to be merged. Each data item contains a 'key' and a 'count'.
   *               Example: [{ key: 'HAMBURG', count: 67 }, { key: 'HAMBURG', count: 30 }]
   *
   * @returns An array of MergedAnalyticData where duplicate entries have been combined.
   *          The 'key' field is an object that contains:
   *              - 'name': The standardized key without spaces (used for display).
   *              - 'values': A set of original keys that were merged.
   *          The 'count' field represents the sum of the counts for the merged entries.
   *
   */
  const mergeData = (data: AnalyticData[]): MergedAnalyticData[] => {

    // Handle edge case when data is null or empty
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    const map = new Map<string, MergedAnalyticData>();

    data.forEach(({ key, count }) => {
      // Normalize the key by calling getRealDestination function
      const normalizedKey = typeof key === 'string' ? getRealDestination(key) : key;

      // If the normalized key already exists, update the existing entry
      if (map.has(normalizedKey)) {
        const existingEntry = map.get(normalizedKey)!;
        existingEntry.count += count;
        existingEntry.key.values.add(key);
      } else {
        // If the normalized key does not exist, create a new entry
        map.set(normalizedKey, {
          key: {
            name: normalizedKey,
            values: normalizedKey==key ? new Set([key]) : new Set([normalizedKey,key]) // save the inital key
          },
          count: count
        });
      }
    });

    // Convert the map values to an array
    return Array.from(map.values());
  };

  let initialData = data ? mergeData(data) : [];
  const [dataToPresent, setDataToPresent] = useState<MergedAnalyticData[] | undefined>(initialData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data && data.length > 0) {
      // Filter out items with an empty key and merge the data
      const mergedData = mergeData(
          data.filter(d => d.key !== '')
              .map(d => {
                const updatedKey = yAxisExtractor
                        ? yAxisExtractor(d.key)
                        : d.key; // Keep the original key if not numeric

                return {
                  ...d,
                  key: updatedKey,
                };
              })
      );
      if(fieldFilterName == "destinations"){
        dispatch(
            setMergeDestination(Array.from(mergedData))
        )
      }

      setDataToPresent(mergedData);
    }
  }, [data]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const filter = generateFilter([
      generateFilterItem(FilterType.COUNT_FIELD, trackQueryField),
      generateFilterItem(FilterType.TIME_RANGE, [moment(filters.timeRange[1]).subtract(15, 'minutes')
          .toISOString(), filters.timeRange[1]]),
      generateFilterItem(FilterType.GEOBOX, [mapBounds.northWest, mapBounds.southEast, filters.timeRange[1]]),
    ]);


    if (mapBounds.northWest && mapBounds.southEast)
      fetchChartData({ data: filter });

    // Stop previous interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Start new interval
    intervalRef.current = setInterval(() => {
      fetchChartData({data: filter});
    }, 10000);

    // Stop the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [filters, mapBounds]);

  useEffect(() => {
    const currSelected = selectedFields[fieldFilterName as keyof typeof selectedFields]
    const filterFields = currSelected.map(selectedFieldsExtractor ? selectedFieldsExtractor : (d) => d)
    dispatch(
        setFilters({
          ...filters,
          [fieldFilterName]: filterFields,
        }),
    );
  }, [selectedFields[fieldFilterName as keyof typeof selectedFields]]);

  const sortData = (d1: AnalyticData, d2: AnalyticData) => {
    if (d1.count === d2.count) {
      return 0;
    }
    return d1.count > d2.count ? -1 : 1;
  };

  const sortDataNew = (d1: MergedAnalyticData, d2: MergedAnalyticData) => {
    if (d1.count === d2.count) {
      return 0;
    }
    return d1.count > d2.count ? -1 : 1;
  };



  const generateOption = (chartType: ChartType) => {
    const chartOptions: any = {
      height: '80px',
      responsive: true,
      maintainAspectRatio: false,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '1%',
        top: '1%',
        containLabel: true,
      },
    };

    switch (chartType) {
      case ChartType.HORIZONTAL_BAR:
        const chartData = dataToPresent
            ? sortDataEnabled
                ? [...dataToPresent].sort(sortDataNew)
                : [...dataToPresent]
            : [];

        chartOptions['xAxis'] = {
          type: 'value',
          nameLocation: 'left',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
        };

        chartOptions['yAxis'] = {
          type: 'category',
          axisLine: { show: false },
          axisTick: { show: false },

          data: chartData
              .slice(0, isMoreDataOpened ? MAXIMUM_DATA_LIMIT : MINIMUM_DATA_LIMIT)
              .map((d) => d.key.name)
              .reverse(),
        };

        chartOptions['series'] = {
          type: 'bar',
          data: chartData
              .slice(0, isMoreDataOpened ? MAXIMUM_DATA_LIMIT : MINIMUM_DATA_LIMIT)
              .map((d, idx) => ({["value"]: d.count, ["itemStyle"]: {["opacity"]: 1}}))
              .reverse(),

          barWidth: 10,
          barGap: 1,
          itemStyle: {
            borderRadius: [5, 5, 5, 5],
          },
          showBackground: true,
          backgroundStyle: {
            color: themeObject[theme].chartBarBackgroundColor,
            borderRadius: [5, 5, 5, 5],
          },
        };

        break;
    }


    if(selectedFields[fieldFilterName as keyof typeof selectedFields].length > 0) {
      //check if data contains any of the selectedFields
      let isAnyFound = false;
      chartOptions['yAxis'].data.forEach((field:string, idx:number) => {
        let index = selectedFields[fieldFilterName as keyof typeof selectedFields].indexOf(field);
        if(index !== -1) {
          isAnyFound = true;
        }
      });

      if(isAnyFound) {
        chartOptions['yAxis'].data.forEach((field:string, idx:number) => {
          let index = selectedFields[fieldFilterName as keyof typeof selectedFields].indexOf(field);
          if(index === -1) {
            chartOptions['series'].data[idx].itemStyle.opacity = 0.4;
          }
        });
      } else {
        //setSelectedFields([]);
      }
    }

    return chartOptions;
  };

  const onChartReadyCallback = (obj:ECharts) => {

    obj.on('click', function(params) {

      //handleSelectedField(params.name);
    });

    obj.getZr().on('click', function (event) {
      // No "target" means that mouse/pointer is not on
      // any of the graphic elements, which is "blank".

      var pointInPixel = [event.offsetX, event.offsetY];
      var pointInGrid = obj.convertFromPixel('grid', pointInPixel);
      /* var category = obj.getOption()['xAxis'][0].data[pointInGrid[0]]*/
      getSelectedFieldFromIndex(pointInGrid[1]);
      if (!event.target) {
        // Click on blank. Do something.
      }
    });




  };

  const getSelectedFieldFromIndex = useCallback((index:number) => {


    const chartData = dataToPresent
        ? sortDataEnabled
            ? [...dataToPresent].sort(sortDataNew)
            : [...dataToPresent]
        : [];


    const  data =  chartData.slice(0, isMoreDataOpened ? MAXIMUM_DATA_LIMIT : MINIMUM_DATA_LIMIT)
        .map((d) => d.key.name)
        .reverse();


    handleFieldSelection(data[index],chartData);

  }, [dataToPresent]);

  const handleFieldSelection = (fieldName: string, chartData: MergedAnalyticData[]) => {
    // Find the corresponding entry in chartData with the matching name
    const matchingData = chartData.find((item) => item.key.name === fieldName);

    if (matchingData) {
      // If found, call handleSelectedField with the corresponding values

      handleSelectedField(Array.from(matchingData.key.values));
    }
  };

  const handleSelectedField = (values: string[]) => {

    let copyItems = [...selectedFields[fieldFilterName as keyof typeof selectedFields]]
    // If any of the values are already in the selected fields, remove all of them
    if (values.some((value) => copyItems.includes(value))) {
      copyItems = copyItems.filter((item) => !values.includes(item));
    } else {
      // If none of the values are in the selected fields, add all of them
      copyItems.push(...values);
    }
    const actionCreator = actionMap[fieldFilterName as keyof typeof actionMap];
    dispatch(
        actionCreator(copyItems)
    )

  };

  const echartRef = useRef<any>();

  useEffect(() => {
    if(echartRef.current != null) {
      const echart:ECharts = echartRef.current!.getEchartsInstance();
      echart.getZr().off('click');
      echart.getZr().on('click', function(event) {
        // No "target" means that mouse/pointer is not on
        // any of the graphic elements, which is "blank".

        var pointInPixel = [event.offsetX, event.offsetY];
        var pointInGrid = echart.convertFromPixel('grid', pointInPixel);
        /* var category = obj.getOption()['xAxis'][0].data[pointInGrid[0]]*/
        getSelectedFieldFromIndex(pointInGrid[1]);
        if (!event.target) {
          // Click on blank. Do something.
        }
      });
    }
  }, [dataToPresent]);




  const renderChart = () => {
    if (Array.isArray(dataToPresent) && dataToPresent.length > 0) {
      const chartOptions = generateOption(type);

      return (
          <S.EChartContainer>

            <S.EChart ref={echartRef} option={chartOptions} />
          </S.EChartContainer>
      );
    }

    return <NoData />;
  };

  return renderChart();
};

export default AnalyticDataChart;
