import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';

interface BtnProps {
    $noStyle?: boolean;
    size?: 'small' | 'middle' | 'large';
}

export const Button = styled(AntButton)<BtnProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;

  ${(props) =>
    props.$noStyle &&
    css`
      width: unset;
      padding: 0;
      height: unset;
    `}
      // Small button styles
  ${(props) =>
          props.size === 'small' &&
          css`
      font-size: 12px;
      padding: 4px 8px;
      height: auto;
    `}

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover {
    color: var(--disabled-color);
  }
  ${(props) =>
    css`
      ${props.type === 'text' &&
      css`
        color: var(--primary-color);
        &:hover {
          background: transparent;
          color: var(--secondary-color);
        }
      `}

      ${props.type === 'primary' &&
      css`
        background: var(--primary-color);

        &:hover {
          background: var(--secondary-color);
          border-color: var(--secondary-color);
        }
      `}

      ${props.type === 'default' &&
      css`
        &:hover {
          background: var(--secondary-color);
          border-color: var(--secondary-color);
        }
      `}

      ${props.type === 'link' &&
      css`
        & span,
        a {
          text-decoration: underline;
        }
      `};

      ${props.type === 'ghost' &&
      css`
        color: var(--primary-color);
        &:hover {
          color: var(--secondary-color);
        }
      `}

      ${props.type === 'dashed' &&
      css`
        color: var(--primary-color);
        &:hover {
          color: var(--secondary-color);
          border-color: var(--secondary-color);
        }
      `}

      ${props.type === 'default' &&
      css`
        color: var(--primary-color);
        &:hover {
          color: var(--secondary-color);
          border-color: var(--secondary-color);
        }
      `}
    `}
`;

