import {Icon} from '@iconify/react';
import React, {useEffect, useRef, useState} from 'react';
import {Marker, TileLayer, useMap} from 'react-leaflet';
import {getROI} from "@app/store/slices/UserContent/thunks";
import {SettingOutlined} from '@ant-design/icons';
import {
    FetchHeatmapForm,
    IMapFilters,
    IMapSettings,
    StreamingCamera,
    Track
} from '@app/common';
import {FetchTracksForm} from '@app/common/formTypes';
import {useAppDispatch, useAppSelector, useResponsive} from '@app/hooks';
import {setStreamingCamera} from '@app/store/slices/Streams/slice';
import {fetchHeatmap, fetchMapTracks, fetchMapTracksHistory} from '@app/store/slices/Tracks/thunks';
import CameraIcon from '@assets/images/camera.svg';
import {
    LeafletGeoExtractorLayer,
    LeafletHeatmapLayer,
    LeafletPositionTrackerLayer,
    LeafletSelectZoomLayer,
    LeafletTrackHistoryLayer,
    LeafletTrackSymbolLayer,
    LeafletTrackDestinationLayer,
} from '@components/index';
import {LeaefletCoordinateGridLayer} from '@components/layers/LeaefletCoordinateGridLayer';
import {GlobalTimeFilter} from '../GlobalTimeFilter';
import {MapLegendCard} from '../MapLegendCard';
import {MapSettingsPopover} from '../MapSettingsPopover';
import {StreamingCard} from '../StreamingCard';
import {VesselCard} from '../VesselCard';
import L, {LatLngExpression, LatLngTuple} from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import * as S from './MariticsMap.style';
import moment from "moment";
import {CancelTokenSource, createCancelToken} from "@api/index"
import {useAuth} from "@app/context/AuthContext";
import MainMapPopover from "../FleetPopover/FleetPopover";
import AnomaliesModal from "@pages/HomePage/components/AnomaliesModal/AnomaliesModal";
import {detectAnomalies} from "@store/slices/AnomalyDetection/thunks";
import {useTranslation} from "react-i18next";

L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});
const MAP_CENTER: LatLngExpression = [35.389543, 35.138804];

interface IMariticsMapProps {
    isSiderOpen: boolean;
    setIsSiderOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ZoomLevel: React.FC<{ onZoomChange: (zoom: number) => void }> = ({onZoomChange}) => {
    const map = useMap();
    const [zoomLevel, setZoomLevel] = useState(map.getZoom());

    useEffect(() => {
        const updateZoomLevel = () => {
            const newZoomLevel = map.getZoom();
            setZoomLevel(newZoomLevel);
            onZoomChange(newZoomLevel);
        };

        map.on('zoomend', updateZoomLevel);

        return () => {
            map.off('zoomend', updateZoomLevel);
        };
    }, [map, onZoomChange]);

    const handleZoomIn = () => {
        map.setZoom(zoomLevel + 1);
    };

    const handleZoomOut = () => {
        map.setZoom(zoomLevel - 1);
    };

    return (
        <div style={{
            position: 'absolute',
            top: '50%',
            right: 10,
            transform: 'translateY(-50%)',
            zIndex: 400,
            backgroundColor: '#4a89d8',
            borderRadius: '8px',
            padding: '2px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        }}
        >
            <button
                onClick={handleZoomIn}
                style={{
                    backgroundColor: '#4a89d8',
                    height: 40,
                    width: 40,
                    border: 'none',
                    outline: 'none',
                    color: '#ffffff',
                    display: 'block',
                    borderBottom: '1px solid #ffffff'
                }}
            >
                <Icon icon="ic:baseline-plus" height={25} color="#ffffff"/>
            </button>
            <button
                onClick={handleZoomOut}
                style={{
                    backgroundColor: '#4a89d8',
                    height: 40,
                    width: 40,
                    border: 'none',
                    outline: 'none',
                    color: '#ffffff',
                    display: 'block',
                    borderRadius: '0 0 8px 8px'
                }}
            >
                <Icon icon="ic:baseline-minus" height={25} color="#ffffff"/>
            </button>
        </div>


    );
};

const MariticsMap: React.FC<IMariticsMapProps> = ({isSiderOpen, setIsSiderOpen}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {isTablet, isDesktop} = useResponsive();
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const showTrackCancelTokenRef = useRef<CancelTokenSource | null>(null);
    const heatmapCancelTokenRef = useRef<CancelTokenSource | null>(null);
    const historyTrackCancelTokenRef = useRef<CancelTokenSource | null>(null);
    const mapBounds = useAppSelector((state) => state.filters.mapBounds);
    const vesselMmsiList = useAppSelector((state) => state.vessel.vesselMmsiList);
    const isOn = useAppSelector((state) => state.vessel.isOn)
    const mapFilters: IMapFilters = useAppSelector((state) => state.filters);
    const mapSettings: IMapSettings = useAppSelector((state) => state.mapSettings);
    const mapTracks: { [key: string]: Track } | null = useAppSelector((state) => state.tracks.mapTracks.data);
    const selectedMapTrackId: string | undefined = useAppSelector((state) => state.tracks.mapTracks.selectedTrackId);
    const selectedMapTrack: Track | null = selectedMapTrackId && mapTracks ? mapTracks[selectedMapTrackId] : null;
    const streamingCameras: StreamingCamera[] = useAppSelector((state) => state.streams.streamingCameras);
    const selectedStreamCamera: StreamingCamera | null = useAppSelector((state) => state.streams.selectedStreamingCamera);
    const [currentZoomLevel, setCurrentZoomLevel] = useState(6);
    const [chosenROIName, setChosenROIName] = useState("");
    const {currentUser} = useAuth();
    const userEmail = currentUser?.email ? currentUser.email : "";




    useEffect(() => {
        const dispatch_body = {
            email: userEmail,
            roiName: chosenROIName
        }
        dispatch(getROI(dispatch_body))
            .unwrap()

    }, [chosenROIName]);


    const triggerDispatchShowTracks = () => {

        dispatch(detectAnomalies());
        const fifteenMinutesAgo = moment(mapFilters.timeRange[1]).subtract(15, 'minutes');
        const sixtyMinutesAgo = moment(mapFilters.timeRange[1]).subtract(60, 'minutes');

        if (!mapSettings.showTracks) {
            return;
        }
        if (!mapBounds) {
            return;
        }
        const fetchMapTracksForm: FetchTracksForm = {

            timeRange: isOn ? [sixtyMinutesAgo.toISOString(), mapFilters.timeRange[1]] : [fifteenMinutesAgo.toISOString(), mapFilters.timeRange[1]],
            geoBox: [mapBounds.northWest, mapBounds.southEast],
            flags: mapFilters.flags,
            vesselTypes: mapFilters.vesselTypes,
            destinations: mapFilters.destinations,
            speeds: mapFilters.speeds,
            mmsi: vesselMmsiList,
            navigationalStatuses: mapFilters.navigationalStatuses,

        };

        if (showTrackCancelTokenRef.current) {
            showTrackCancelTokenRef.current.cancel(t("pages.home.canceledOp"));
        }

        showTrackCancelTokenRef.current = createCancelToken();

        dispatch(fetchMapTracks({filter: fetchMapTracksForm, cancelToken: showTrackCancelTokenRef.current.token}))


    };
    const triggerDispatchTrackHistory = () => {
        const fetchMapTracksForm: FetchTracksForm = {
            timeRange: [moment(mapFilters.timeRange[1]).subtract(1, 'w').toISOString(), mapFilters.timeRange[1]],
            geoBox: [mapBounds.northWest, mapBounds.southEast],
            mmsi: selectedMapTrackId,
            flags: mapFilters.flags,
            vesselTypes: mapFilters.vesselTypes,
            destinations: mapFilters.destinations,
            navigationalStatuses: mapFilters.navigationalStatuses,
        };


        if (historyTrackCancelTokenRef.current) {
            historyTrackCancelTokenRef.current.cancel(t("pages.home.canceledOp"));
        }

        historyTrackCancelTokenRef.current = createCancelToken();

        dispatch(fetchMapTracksHistory({
            filter: fetchMapTracksForm,
            cancelToken: historyTrackCancelTokenRef.current.token
        }));
    };
    const triggerDispatchHeatmap = () => {


        if (!mapSettings.showHeatmap) {
            return;
        }

        if (!mapBounds) {
            return;
        }

        const fetchHeatmapForm: FetchHeatmapForm = {
            timeRange: mapFilters.timeRange,
            geoBox: [mapBounds.northWest, mapBounds.southEast],
            flags: mapFilters.flags,
            vesselTypes: mapFilters.vesselTypes,
            destinations: mapFilters.destinations,
            speeds: mapFilters.speeds,
            navigationalStatuses: mapFilters.navigationalStatuses,
        };


        if (heatmapCancelTokenRef.current) {
            heatmapCancelTokenRef.current.cancel(t("pages.home.canceledOp"));
        }

        heatmapCancelTokenRef.current = createCancelToken();

        dispatch(fetchHeatmap({filter: fetchHeatmapForm, cancelToken: heatmapCancelTokenRef.current.token}));
    };

    useEffect(() => {
        if (mapBounds.northWest && mapBounds.southEast) triggerDispatchHeatmap();
        let interval = setInterval(() => {
            triggerDispatchHeatmap();
        }, mapSettings.refreshRate);
        return () => clearInterval(interval);
    }, [
        mapSettings.showHeatmap,
        mapSettings.refreshRate,
        mapFilters.mapBounds,
        mapFilters.timeRange,
        mapFilters.flags,
        mapFilters.vesselTypes,
        mapFilters.destinations,
        mapFilters.speeds,
        mapFilters.navigationalStatuses
    ]);


    useEffect(() => {

        if (mapBounds.northWest && mapBounds.southEast) triggerDispatchShowTracks();

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
            triggerDispatchShowTracks();
        }, mapSettings.refreshRate);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [
        mapSettings.showTracks,
        mapSettings.refreshRate,
        mapBounds,
        mapFilters.trackLast,
        mapFilters.timeRange,
        mapFilters.flags,
        mapFilters.vesselTypes,
        mapFilters.destinations,
        mapFilters.speeds,
        mapFilters.navigationalStatuses,
        vesselMmsiList,
        isOn,

    ]);

    useEffect(() => {
        if (selectedMapTrackId) triggerDispatchTrackHistory();
    }, [selectedMapTrackId, mapFilters.timeRange]);


    const createCameraMarker = (camera: StreamingCamera) => {
        const cameraIcon = new L.Icon({
            iconUrl: CameraIcon,
            iconSize: new L.Point(35, 35),
        });

        return (
            <Marker
                key={camera.url}
                position={camera.coordinates as LatLngTuple}
                icon={cameraIcon}
                eventHandlers={{
                    click: () => {
                        dispatch(setStreamingCamera(camera));
                    },
                }}
            />
        );
    };

    return (
        <React.Fragment>
            <S.MapOuterContainer>
                <S.MapContainer center={MAP_CENTER} zoom={6} maxZoom={20} minZoom={1} zoomControl={false}>
                    <ZoomLevel onZoomChange={(zoom) => setCurrentZoomLevel(zoom)}/>
                    {mapSettings.isSatelliteView ? //change the map appearance
                        <TileLayer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            attribution='&copy; <a href="https://www.esri.com/">Esri</a>, USGS, NOAA'
                            keepBuffer={10}
                        /> : // satellite view
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" keepBuffer={10}/> //street view
                    }x
                    <TileLayer url="https://t1.openseamap.org/seamark/{z}/{x}/{y}.png" keepBuffer={10}/>
                    <TileLayer url="https://t1.openseamap.org/sport/{z}/{x}/{y}.png" keepBuffer={10}/>

                    <LeafletPositionTrackerLayer/>
                    <LeafletSelectZoomLayer/>
                    <LeafletGeoExtractorLayer/>

                    <S.SettingsDropdown
                        customPopover={<MapSettingsPopover/>}
                        buttonContent={<S.SettingsOverlayToggler icon={<SettingOutlined/>}/>}
                    />
                    <div style={{
                        position: 'absolute',
                        top: 70,
                        right: 10,
                        zIndex: 1000,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <MainMapPopover
                            zoomLevel={currentZoomLevel}
                        />
                    </div>
                    <div style={{
                        position: 'absolute',
                        top: 70,
                        right: 10,
                        zIndex: 1000,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <AnomaliesModal/>
                    </div>
                    {mapSettings.showCoordinateGrids && <LeaefletCoordinateGridLayer/>}
                    {mapSettings.showHeatmap && <LeafletHeatmapLayer/>}
                    {mapSettings.showTracks && <LeafletTrackHistoryLayer/>}
                    {mapSettings.showTracks && <LeafletTrackSymbolLayer/>}
                    {mapSettings.showTracks && mapSettings.showDestination && <LeafletTrackDestinationLayer/>}

                    {currentZoomLevel >= 7 && streamingCameras.map((cam) => createCameraMarker(cam))}
                </S.MapContainer>

                <GlobalTimeFilter/>
                <MapLegendCard/>
                {selectedMapTrack && isTablet && (
                    <S.VesselCardContainer>
                        <VesselCard track={selectedMapTrack} direction="vertical"/>
                    </S.VesselCardContainer>
                )}
                {selectedStreamCamera && isDesktop && (
                    <S.StreamingCardContainer>
                        <StreamingCard direction="horizontal" camera={selectedStreamCamera}/>
                    </S.StreamingCardContainer>
                )}
            </S.MapOuterContainer>
        </React.Fragment>
    );
};

export default MariticsMap;