// slicer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedFieldsState {
    flags: string[];
    vesselTypes: string[];
    destinations: string[];
    speeds: string[];
    navigationalStatuses: string[];
}

const initialState: SelectedFieldsState = {
    flags: [],
    vesselTypes: [],
    destinations: [],
    speeds: [],
    navigationalStatuses: [],
};

const selectedFieldsSlice = createSlice({
    name: 'selectedFields',
    initialState,
    reducers: {
        setFlags(state, action: PayloadAction<string[]>) {
            state.flags = action.payload;
        },
        setVesselTypes(state, action: PayloadAction<string[]>) {
            state.vesselTypes = action.payload;
        },
        setDestinations(state, action: PayloadAction<string[]>) {
            state.destinations = action.payload;
        },
        setSpeeds(state, action: PayloadAction<string[]>) {
            state.speeds = action.payload;
        },
        setNavigationalStatuses(state, action: PayloadAction<string[]>) {
            state.navigationalStatuses = action.payload;
        },
    },
});

export const {
    setFlags,
    setVesselTypes,
    setDestinations,
    setSpeeds,
    setNavigationalStatuses,
} = selectedFieldsSlice.actions;

export default selectedFieldsSlice.reducer;
