import axios from 'axios';
import { BASE_COLORS, IMapSettings, Location, Track } from '@app/common';
import { useAppSelector } from '@app/hooks';
import L from 'leaflet.geodesic';
import React, { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import {useTranslation} from "react-i18next";

const LeafletTrackDestinationLayer: React.FC = () => {
  const { t } = useTranslation();
  // Define the base path for the API from environment variables
  const BASE_PATH = process.env.REACT_APP_LOCATION_PATH;
  const LOCATION_PATH = `${BASE_PATH}/locode?code=`;

  // Get map settings from Redux store
  const mapSettings: IMapSettings = useAppSelector((state) => state.mapSettings);
  const map = useMap(); // Access the Leaflet map instance

  const currentRequestId = useRef<number>(0); // Track the current request ID
  const cancelSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null); // Store the cancel token for the current request

  // Get tracks and the selected track ID from Redux store
  const tracks: { [key: string]: Track } | null = useAppSelector((state) => state.tracks.mapTracks.data);
  const selectedMapTrackId: string | undefined = useAppSelector((state) => state.tracks.mapTracks.selectedTrackId);

  // Function to fetch location data based on the provided code
  const getLocation = async (code: string, requestId: number) => {
    try {
      // Cancel the previous request if it exists
      if (cancelSource.current) {
        cancelSource.current.cancel(t("components.layers.canceledDue"));
      }

      // Create a new cancel token source for the current request
      cancelSource.current = axios.CancelToken.source();

      // Make the API request to fetch location data
      const response = await axios.post(
          `${LOCATION_PATH}${code}`,
          {},
          {
            cancelToken: cancelSource.current.token, // Attach the cancel token to the request
            transformResponse: (data: any) => {
              try {
                const json = JSON.parse(data);
                const lat = parseFloat(json.lat);
                const lon = parseFloat(json.lon);

                // Validate latitude and longitude
                if (isNaN(lat) || isNaN(lon)) {
                  console.error(t("components.layers.invalidLatLng"), lat, lon);
                  return null;
                }

                return { lat, lon } as Location; // Return the parsed location
              } catch (e) {
                return null; // Return null on error
              }
            }
          }
      );

      // Ignore the response if a newer request is already made
      if (requestId !== currentRequestId.current) {
        return null;
      }

      return response.data; // Return the fetched location data
    } catch (error) {
      if (axios.isCancel(error)) {
        //console.log("Request canceled:", error.message); // Log cancellation message
      } else {
        console.error(t("components.layers.errorFetchingLoc"), error); // Log other errors
      }
      return null; // Return null on error
    }
  };

  // Function to create polyline positions for the selected track's destination
  const createPolylinePositionsForSelectedDestination = async (track: Track, requestId: number) => {
    if (!track) return null; // Return null if track is not provided

    const polylinePositions: { fromPosition: [number, number]; toPosition: [number, number]; }[] = [];

    // Check if the track has a destination
    if (track.destination && track.destination.length > 0) {
      const destination = await getLocation(track.destination, requestId); // Fetch the destination location
      if (!destination) return null; // Return null if destination fetching failed

      // Add polyline positions from the current location to the destination
      polylinePositions.push({
        fromPosition: [track.location.lat, track.location.lon],
        toPosition: [destination.lat, destination.lon],
      });
    } else {
      return null; // Return null if no destination is set
    }

    return polylinePositions; // Return the polyline positions
  };

  useEffect(() => {
    // Check if tracks, selected track ID, or showDestination flag are not set
    if (!tracks || !selectedMapTrackId || !mapSettings.showDestination) {
      //console.log("Track Unselected or showDestination is turned off");
      currentRequestId.current = 0; // Reset request ID
      return;
    }

    //console.log("Track Selected");
    currentRequestId.current += 1; // Increment the current request ID
    const requestId = currentRequestId.current; // Store the current request ID

    let geodesiclines: L.GeodesicLine[] = []; // Array to hold drawn geodesic lines
    const selectedTrack = tracks[selectedMapTrackId]; // Get the selected track

    // Function to mark the destination on the map
    const markDestinationOnMap = async (track: Track, requestId: number) => {
      const polylinePositions = await createPolylinePositionsForSelectedDestination(track, requestId); // Get polyline positions

      // Check if polyline positions are valid or if the settings have changed
      if (!polylinePositions || requestId !== currentRequestId.current || !mapSettings.showDestination) {
        console.error(t("components.layers.noPolyline"));
        return; // Exit if conditions are not met
      }

      // Loop through the polyline positions and draw them on the map
      polylinePositions.forEach((p) => {
        if (
            Array.isArray(p.fromPosition) &&
            p.fromPosition.length === 2 &&
            typeof p.fromPosition[0] === "number" &&
            typeof p.fromPosition[1] === "number" &&
            Array.isArray(p.toPosition) &&
            p.toPosition.length === 2 &&
            typeof p.toPosition[0] === "number" &&
            typeof p.toPosition[1] === "number"
        ) {
          const polylineObject = new L.GeodesicLine([p.fromPosition, p.toPosition], {
            color: BASE_COLORS.blue,
            opacity: 0.7,
            steps: 6,
          });
          polylineObject.addTo(map); // Add the polyline to the map
          geodesiclines.push(polylineObject); // Store the polyline object for cleanup
        } else {
          console.error(t("components.layers.invalidPolyline"), p); // Log invalid polyline position
        }
      });
    };

    markDestinationOnMap(selectedTrack, requestId); // Mark the destination on the map

    // Cleanup function to cancel ongoing requests and remove drawn lines
    return () => {
      if (cancelSource.current) {
        cancelSource.current.cancel(t("components.layers.cleanUp")); // Cancel ongoing request
      }
      geodesiclines.forEach((line) => {
        map.removeLayer(line); // Remove each drawn line from the map
      });
      geodesiclines = []; // Reset the geodesic lines array
    };
  }, [selectedMapTrackId, mapSettings]); // Re-run effect when dependencies change

  return <div></div>; // Return an empty div (or you can replace with your own JSX)
};

export default LeafletTrackDestinationLayer;
