import React from 'react';
import {Modal, Input} from 'antd';
import {useTranslation} from "react-i18next";

interface AddFleetModalProps {
    visible: boolean;
    newFleetName: string;
    setNewFleetName: (name: string) => void;
    newFleetColor: string;
    setNewFleetColor: (color: string) => void;
    handleAddFleetConfirm: () => void;
    onCancel: () => void;
}

const AddFleetModal: React.FC<AddFleetModalProps> = ({
                                                         visible,
                                                         newFleetName,
                                                         setNewFleetName,
                                                         newFleetColor,
                                                         setNewFleetColor,
                                                         handleAddFleetConfirm,
                                                         onCancel
                                                     }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t("pages.account.fleetAdd.addNewFleet")}
            visible={visible}
            onOk={handleAddFleetConfirm}
            onCancel={onCancel}
            okText={t("common.add")}
            cancelText={t("common.cancel")}
        >
            <Input
                placeholder={t("pages.account.fleetOverview.fleetName")}
                value={newFleetName}
                onChange={(e) => setNewFleetName(e.target.value)}
            />
            <div style={{ marginTop: '10px' }}>
                <label htmlFor="colorPicker">{t("pages.home.popover.addFleet.chooseColor")}</label>
                <input
                    id="colorPicker"
                    type="color"
                    value={newFleetColor}
                    onChange={(e) => setNewFleetColor(e.target.value)}
                    style={{ marginLeft: '10px' }}
                />
            </div>
        </Modal>
    );
};

export default AddFleetModal;
