import React, {useEffect, useRef, useState} from 'react';
import * as S from './FleetView.style';
import {Button, message, Modal, notification, Tooltip} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {ColumnType} from 'antd/es/table';
import {CancelTokenSource, createCancelToken} from "@api/index"
import {fetchMapTracks} from '@store/slices/Tracks/thunks';
import moment from 'moment';
import {Countries, FetchTracksForm, IMapFilters} from '@app/common';
import {useAppDispatch, useAppSelector} from '@app/hooks';

import {Vessel, Fleet} from "@app/common";
import {useTranslation} from "react-i18next";



interface fleetViewProps {
    fleet: Fleet | null;
    onBack: () => void;
    onDeleteVessel: (fleetName: string, vesselMmsi: string, emailToFetch: string) => Promise<void>;
}

const fleetView: React.FC<fleetViewProps> = ({ fleet, onBack, onDeleteVessel }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [vesselDetails, setVesselDetails] = useState<{ [key: string]: Vessel }>({});
    const showTrackCancelTokenRef = useRef<CancelTokenSource | null>(null);
    const emailToFetch = "contact.ogunisik@gmail.com";
    const dispatch = useAppDispatch();
    const mapFilters: IMapFilters = useAppSelector((state) => state.filters);

    const formatPosition = (position: string | undefined) => {
        if (!position) return t('pages.account.fleetView.unavailable');

        const [latLabel, lonLabel] = position.split('\n');

        return (
            <div style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                <div>
                    <span style={{ fontWeight: 'bold' }}>{t('pages.account.fleetView.latitude')}: </span>{latLabel}
                </div>
                <div>
                    <span style={{ fontWeight: 'bold' }}>{t('pages.account.fleetView.longitude')}: </span>{lonLabel}
                </div>
            </div>
        );
    };

    const formatLastUpdatedTime = (time: string | undefined) => {
        if (!time) return t('pages.account.fleetView.unavailable');
        return moment(time).format('YYYY-MM-DD HH:mm:ss');
    };

    const getVesselFlag = (countryCode: string | undefined) => {
        const unknownFlagOutput = (
            <Tooltip title={t('pages.account.fleetView.unknown')}>
                <S.UnknownFlag />
            </Tooltip>
        );

        if (!countryCode) {
            return unknownFlagOutput;
        }

        const countryName = Countries[countryCode as keyof typeof Countries];

        return countryName ? (
            <Tooltip title={countryName}>
                <S.CountryFlag svg countryCode={countryCode} />
            </Tooltip>
        ) : (
            unknownFlagOutput
        );
    };

    const funct = (fleet) => {
        if (fleet) {
            const mmsiList = fleet.vessels.map(vessel => vessel.mmsi);

            if (showTrackCancelTokenRef.current) {
                showTrackCancelTokenRef.current.cancel(t('pages.account.fleetView.operationCanceled'));
            }

            showTrackCancelTokenRef.current = createCancelToken();

            if (mmsiList && mmsiList.length > 0) {
                const OneYearAgo = moment(mapFilters.timeRange[1]).subtract(1, 'year');

                const fetchMapTracksForm: FetchTracksForm = {
                    timeRange: [OneYearAgo.toISOString(), mapFilters.timeRange[1]],
                    geoBox: [[89.9, -179.9], [-89.9, 179.9]],
                    mmsi: mmsiList
                };

                dispatch(fetchMapTracks({ filter: fetchMapTracksForm }))
                    .unwrap()
                    .then((data) => {
                        if (!data) {
                            console.error(t('pages.account.fleetView.noDataReturned'));
                            return;
                        }


                        const updatedVesselDetails: { [key: string]: Vessel } = {};

                        mmsiList.forEach((mmsi) => {
                            if (data[mmsi] ) {
                                const latestData = data[mmsi]; // En son alınan verinin ilk elemanı
                                updatedVesselDetails[mmsi] = {
                                    mmsi: latestData.mmsi,
                                    imo: latestData.imo,
                                    name: latestData.name,
                                    vesselType: latestData.vesselType,
                                    flag: latestData.flag,
                                    lastUpdateTime: latestData.timestamp,
                                    position: `${latestData.location.lat.toFixed(4)}\n${latestData.location.lon.toFixed(4)}`,
                                };
                            } else {
                                console.warn(t('pages.account.fleetView.noDataForMMSI', { mmsi }));
                            }
                        });

                        setVesselDetails(updatedVesselDetails);
                    })
                    .catch((error) => {
                        console.error(t('pages.account.fleetView.errorFetchingDetails', { error }));
                    });
            } else {
                console.error(t('pages.account.fleetView.emptyMMSIList'));
            }
        }
    };

    useEffect(() => {
        funct(fleet);
    }, [fleet]);

    const handleDeleteVessel = (vesselMmsi: string) => {
        Modal.confirm({
            title: t('pages.account.fleetView.confirmDeleteTitle'),
            content: `${t('pages.account.fleetView.confirmDeleteContent')} ${vesselMmsi}`,
            okText: t('common.yes'),
            okType: 'danger',
            cancelText: t('common.cancel'),
            onOk: async () => {
                setLoading(true);
                try {
                    await onDeleteVessel(fleet?.name ?? '', vesselMmsi, emailToFetch);
                    message.success(t('pages.account.fleetView.vesselDeleted'));
                } catch (error) {
                    console.error(t('pages.account.fleetView.errorDeletingVessel', { error }));
                    notification.error({
                        message: t('common.error'),
                        description: t('pages.account.fleetView.failedToDeleteVessel'),
                    });
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    const transformedVessels = fleet
        ? fleet.vessels.map(vessel => {
            const details = vesselDetails[vessel.mmsi] || {};
            return {
                mmsi: vessel.mmsi,
                imo: details.imo,
                name: details.name,
                vesselType: details.vesselType,
                flag: getVesselFlag(details.flag),
                lastUpdateTime: formatLastUpdatedTime(details.lastUpdateTime),
                position: formatPosition(details.position),
                actions: (
                    <Tooltip title={t('pages.account.fleetView.deleteVessel')}>
                        <Button
                            type="text"
                            icon={<DeleteOutlined style={{ color: 'red' }} />}
                            onClick={() => handleDeleteVessel(vessel.mmsi)}
                            loading={loading}
                            style={{ marginLeft: 8 }}
                        />
                    </Tooltip>
                ),
            };
        })
        : [];

    const columns: ColumnType<any>[] = [
        { title: t('pages.account.fleetView.mmsiNumber'), dataIndex: 'mmsi', key: 'mmsi' },
        { title: t('pages.account.fleetView.imo'), dataIndex: 'imo', key: 'imo' },
        { title: t('pages.account.fleetView.name'), dataIndex: 'name', key: 'name' },
        { title: t('pages.account.fleetView.vesselType'), dataIndex: 'vesselType', key: 'vesselType' },
        {
            title: t('pages.account.fleetView.flag'),
            dataIndex: 'flag',
            key: 'flag',
            render: (flag) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {flag}
                </div>
            ),
        },
        {
            title: t('pages.account.fleetView.position'),
            dataIndex: 'position',
            key: 'position',
            render: (position) => (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {position}
                </div>
            ),
        },
        {
            title: t('pages.account.fleetView.lastUpdated'),
            dataIndex: 'lastUpdateTime',
            key: 'lastUpdateTime',
            render: (lastUpdateTime) => (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {lastUpdateTime}
                </div>
            ),
        },
        {
            title: t('pages.account.fleetView.deleteVessel'),
            key: 'actions',
            render: (_, record) => record.actions,
        },
    ];

    return fleet ? (
        <div>
            <S.FleetName>{t('pages.account.fleetView.fleetName', { fleetName: fleet.name })}</S.FleetName>
            <S.CustomTable
                dataSource={transformedVessels}
                columns={columns}
                rowKey="mmsi"
                rowClassName="customRow"
            />
            <S.CustomButton onClick={onBack}>{t('pages.account.fleetView.backToFleets')}</S.CustomButton>
        </div>
    ) : (
        <div>
            <p>{t('pages.account.fleetView.noFleetSelected')}</p>
            <S.CustomButton onClick={onBack}>{t('pages.account.fleetView.backToFleets')}</S.CustomButton>
        </div>
    );
};

export default fleetView;
