import React, {useEffect, useState} from 'react';
import * as S from './AccountOverview.style';
import {User} from "@app/common";
import {useAuth} from "@app/context/AuthContext";
import {useAppSelector} from "@app/hooks";
import { useTranslation} from "react-i18next";

interface AccountOverviewProps {
    user: User;
    onViewFleets: () => void;
    onViewROIs: () => void;
}

const AccountOverview: React.FC<AccountOverviewProps> = ({ user, onViewFleets, onViewROIs }) => {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const [displayName, setDisplayName] = useState<string | null>(currentUser?.displayName || null);
    const newName = useAppSelector(state => state.roi.fullName)

    useEffect(() => {
        if(newName) {
            setDisplayName(newName)
        }
        else{
            setDisplayName(currentUser?.displayName || null);
        }
    }, [newName, currentUser]);
    if (!user) {
        return <div>{t("pages.account.overview.noData")}</div>;
    }
    return (
        <S.Container>
            <S.Title>{t("pages.account.overview.accountOver")}</S.Title>

            <S.InfoRow>
                <S.Label>{t("pages.account.overview.yourName")}</S.Label>
                <S.Value>{displayName}</S.Value>
            </S.InfoRow>

            <S.InfoRow>
                <S.Label>{t("pages.account.overview.yourEmail")}</S.Label>
                <S.Value>{user.email}</S.Value>
            </S.InfoRow>

            <S.InfoRow>
                <S.Label>{t("pages.account.overview.accountCreate")}</S.Label>
                <S.Value>{user.accountCreatedAt}</S.Value>
            </S.InfoRow>

            <S.ButtonContainer>
                <S.Label>{t("pages.account.overview.fleetOver")}</S.Label>
                <S.CustomButton onClick={onViewFleets}>{t("pages.account.overview.viewFleet")}</S.CustomButton>
            </S.ButtonContainer>

            <S.ButtonContainer>
                <S.Label>{t("pages.account.overview.roiOver")}</S.Label>
                <S.CustomButton onClick={onViewROIs}>{t("pages.account.overview.viewROIs")}</S.CustomButton>
            </S.ButtonContainer>
        </S.Container>
    );
};

export default AccountOverview;
