import mapSettingsReducer from '@app/store/slices/MapSettings/slice';
import tracksReducer from '@app/store/slices/Tracks/slice';
import filtersReducer from '@store/slices/Filters/slice';
import themeReducer from '@app/store/slices/Theme/slices';
import streamsReducer from '@store/slices/Streams/slice';
import userReducer from '@store/slices/UserContent/slice';
import vesselReducer from "@store/slices/AllTracks/VesselSlice";
import roiSlice from "@store/slices/UserContent/slice";
import selectedFieldReducer from '@app/store/slices/SelectedFilters/slice';
import mergeDestinationReducer from '@app/store/slices/MergedDataDestination/slice';
import {incrementROI, decrementROI} from "@store/slices/UserContent/slice";

import anomaliesSlice from "@store/slices/AnomalyDetection/slice";
const rootReducer = {
  theme: themeReducer,
  filters: filtersReducer,
  selectedFilters : selectedFieldReducer,
  mergeDestinations : mergeDestinationReducer,
  mapSettings: mapSettingsReducer,
  tracks: tracksReducer,
  streams: streamsReducer,
  user: userReducer,
  vessel: vesselReducer,
  roi: roiSlice,
  anomalyDetection: anomaliesSlice,


};

export default rootReducer;
