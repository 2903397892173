import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "@common/index";
import { Dates } from "@services/index";

const storedLanguage = (localStorage.getItem("lng") as Language) || "en";

const useLanguage = (): {
    language: Language;
    setLanguage: (locale: Language) => Promise<void>;
} => {
    const { i18n } = useTranslation();

    const handleChangeLanguage = useCallback(
        async (locale: Language) => {
            Dates.setLocale(locale);
            localStorage.setItem("lng", locale);
            await i18n.changeLanguage(locale);
        },
        [i18n]
    );

    useEffect(() => {
        if (i18n.language !== storedLanguage) {
            handleChangeLanguage(storedLanguage);
        }
    }, [handleChangeLanguage]);

    return useMemo(
        () => ({
            language: i18n.language as Language,
            setLanguage: handleChangeLanguage,
        }),
        [handleChangeLanguage, i18n.language]
    );
};

export default useLanguage;
