import React from 'react';
import { ProgressBarWrapper, ProgressBar, ROIOverviewContainer, ROIUsageBox, interpolateColor } from './ROIUsagePercentage.style';
import {useTranslation} from "react-i18next";
interface ROIUsagePercentageProps {
    roiUsage: number;
}

const ROIUsagePercentage: React.FC<ROIUsagePercentageProps> = ({ roiUsage }) => {
    const {t }= useTranslation();
    const percentage = (roiUsage / 5) * 100;
    const color = interpolateColor(roiUsage, 0, 5);

    return (
        <ROIUsageBox>
            <ROIOverviewContainer>
                <p>{t("pages.account.ROIList.usage")} {roiUsage}/5</p>
                <ProgressBarWrapper>
                    <ProgressBar width={`${percentage}%`} color={color} />
                </ProgressBarWrapper>
            </ROIOverviewContainer>
        </ROIUsageBox>
    );
};

export default ROIUsagePercentage;
