import React, { useState } from 'react';
import { Form } from 'antd';
import { useAuth } from '@app/context/AuthContext';
import * as S from './ChangeFullName.style';
import {setFullName} from "@store/slices/UserContent/slice";
import {useAppDispatch} from "@app/hooks";
import {useTranslation} from "react-i18next";

const ChangeFullNameForm: React.FC = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { currentUser, changeFullName } = useAuth();
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const validateFullName = (_: any, value: string) => {
        const words = value.trim().split(/\s+/);
        if ((value && words.length < 2) || words.some(word => word.length < 2)) {
            return Promise.reject(t("pages.account.changeName.atLeastTwo"));
        }
        if (value && words.length > 3) {
            return Promise.reject(t("pages.account.changeName.atMostThree"));
        }
        return Promise.resolve();
    };

    const handleSubmit = async (values: { fullName: string }) => {
        const { fullName } = values;

        setError(null);
        setSuccess(false);

        try {
            await changeFullName(fullName);
            setSuccess(true);
            form.setFieldsValue({ fullName });
            dispatch(setFullName(fullName));
        } catch (error: any) {
            setError(error.message);
        }
    };
    const handleInputChange = () => {
        if (success) {
            setSuccess(false);
        }
    };
    return (
        <S.Container>
            <S.Title>{t("pages.account.changeName.changeFullName")}</S.Title>
            <Form form={form} onFinish={handleSubmit}>
                <S.Label htmlFor="fullName">New Full Name</S.Label>
                <S.InputContainer>
                    <Form.Item
                        name={t("pages.account.changeName.newFullName")}
                        initialValue={currentUser?.displayName}
                        rules={[
                            { required: true, message: t("pages.account.changeName.messageFullName") },
                            { validator: validateFullName }
                        ]}
                        validateTrigger="onBlur"
                    >
                        <S.TextInput
                            id="fullName"
                            placeholder= {t("pages.account.changeName.enterNewName")}
                            onChange={handleInputChange}
                        />
                    </Form.Item>
                </S.InputContainer>

                {error && <S.Error>{error}</S.Error>}
                {success && <S.Success>{t("pages.account.changeName.nameChanged")}</S.Success>}

                <Form.Item>
                    <S.Button type="submit">{t("pages.account.changeName.changeName")}</S.Button>
                </Form.Item>
            </Form>
        </S.Container>
    );
};

export default ChangeFullNameForm;
