import React, { useState } from 'react';
import { Form } from 'antd';
import { useAuth } from '@app/context/AuthContext';
import * as S from './ChangePassword.style';
import {useTranslation} from "react-i18next";

const ChangePasswordForm: React.FC = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { changePassword } = useAuth();
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);

    const handleSubmit = async (values: { oldPassword: string; newPassword: string; confirmPassword: string }) => {
        const { oldPassword, newPassword, confirmPassword } = values;

        setError(null);
        setSuccess(false);

        if (newPassword !== confirmPassword) {
            setError(t("pages.account.changePassword.newPMacth"));
            return;
        }

        try {
            await changePassword(oldPassword, newPassword);
            setSuccess(true);
            form.resetFields();
        } catch (error: any) {
            setError(error.message);
        }
    };

    return (
        <S.Container>
            <S.Title>{t("pages.account.changePassword.changeP")}</S.Title>
            <Form form={form} onFinish={handleSubmit}>
                <S.Label htmlFor="oldPassword">{t("pages.account.changePassword.oldP")}</S.Label>
                <S.InputContainer>
                    <Form.Item
                        name="oldPassword"
                        rules={[{ required: true, message: t("pages.account.changePassword.inpOldP") }]}
                    >
                        <S.PasswordInput
                            id="oldPassword"
                            placeholder={t("pages.account.changePassword.enterOldP")}
                        />
                    </Form.Item>
                </S.InputContainer>

                <S.Label htmlFor="newPassword">{t("pages.account.changePassword.newP")}</S.Label>
                <S.InputContainer>
                    <Form.Item
                        name="newPassword"
                        rules={[{ required: true, message: t("pages.account.changePassword.inpNewP") }]}
                    >
                        <S.PasswordInput
                            id="newPassword"
                            placeholder={t("pages.account.changePassword.enterNewP")}
                        />
                    </Form.Item>
                </S.InputContainer>

                <S.Label htmlFor="confirmPassword">{t("pages.account.changePassword.confirmP")}</S.Label>
                <S.InputContainer>
                    <Form.Item
                        name="confirmPassword"
                        rules={[{ required: true, message: t("pages.account.changePassword.pleaseConfirmNewP") }]}
                    >
                        <S.PasswordInput
                            id="confirmPassword"
                            placeholder={t("pages.account.changePassword.confirmNewP")}
                        />
                    </Form.Item>
                </S.InputContainer>

                {error && <S.Error>{error}</S.Error>}
                {success && <S.Success>{t("pages.account.changePassword.pChanged")}</S.Success>}

                <Form.Item>
                    <S.Button type="submit">{t("pages.account.changePassword.changeP")}</S.Button>
                </Form.Item>
            </Form>
        </S.Container>
    );
};

export default ChangePasswordForm;
