import {useTranslation} from "react-i18next";
/**
 * Takes the track destination string and returns the real destination
 * according to the specified rules.
 *
 * @param destination - The destination string
 * @returns The resolved destination based on the rules
 */

export function getRealDestination(destination: string): string {
    //const {t} = useTranslation(); //TO DO: got error here !
    let result = destination;
    if (result.includes(">")) {
        // Extract the next port of call after the separator ">"
        result = destination.split(">")[1].trim();
    }
    if (result.startsWith("===")) {
        // General area is known, return the name after the "==="
        result = result.slice(3).trim();
    }
    if (result === "?? ???") {
        return "Unknown Destination";
    } else if (result.startsWith("XX")) {
        return "Unknown Departure";
    }
    if(result.length < 7) {
        result = result.replace(/\s+/g, ''); // Remove all spaces
    }

    return result;
}