import React from 'react';
import { List, Button, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface Fleet {
    id: string; // Assuming each fleet has a unique identifier
    name: string;
    color: string;
}

interface FleetListProps {
    fleets: Fleet[];
    handleFetchFleetVessels: (fleetName: string) => void;
    handleEditFleet: (index: number) => void;
    handleDeleteFleet: (index: number) => void;
}

const FleetList: React.FC<FleetListProps> = ({
                                                 fleets,
                                                 handleFetchFleetVessels,
                                                 handleEditFleet,
                                                 handleDeleteFleet
                                             }) => {
    const { t } = useTranslation(); // Use translation for internationalization

    return (
        <List
            header={<div>{t("pages.home.popover.fleetList.myFleets")}</div>} // Localized header
            bordered
            dataSource={fleets}
            renderItem={(item, index) => (
                <List.Item
                    actions={[
                        <Tooltip title={t("pages.home.popover.fleetList.editFleet")} key={`edit-${item.id}`}>
                            <Button
                                type="text"
                                icon={<EditOutlined style={{ color: 'blue' }} />}
                                onClick={() => handleEditFleet(index)}
                            />
                        </Tooltip>,
                        <Tooltip title={t("pages.home.popover.fleetList.deleteFleet")} key={`delete-${item.id}`}>
                            <Button
                                type="text"
                                icon={<DeleteOutlined style={{ color: 'red' }} />}
                                onClick={() => handleDeleteFleet(index)}
                            />
                        </Tooltip>
                    ]}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            backgroundColor: item.color,
                            marginRight: 8
                        }}></div>
                        <Button type="link" onClick={() => handleFetchFleetVessels(item.name)}>
                            {item.name}
                        </Button>
                    </div>
                </List.Item>
            )}
            style={{ marginTop: 16 }}
        />
    );
};

export default FleetList;
