import { AppLogo, AppLogoTitle } from '@app/components/common';
import { Row, Col } from 'antd';
import React from 'react';
import { AccountDropdown } from "@components/layout/Header/AccountDropdown";
import InlineMenu from "@components/layout/Header/MenuDropdown/MenuDropdown";

const CollapsedHeader: React.FC = () => {
    return (
        <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            {/* Menu Dropdown */}
            <Col>
                <InlineMenu />
            </Col>

            {/* Grouping AppLogo and AppLogoTitle close together */}
            <Col>
                <Row align="middle">
                    <AppLogo width="25px" height="25px" />
                    <AppLogoTitle title="Maritics" />
                </Row>
            </Col>

            {/* Account Dropdown on the right */}
            <Col>
                <AccountDropdown />
            </Col>
        </Row>
    );
};

export default CollapsedHeader;
