import {useEffect, useRef, useState} from 'react';
import {AxiosRequestConfig, CancelTokenSource, CancelToken, createCancelToken, isCancel} from '@api/index';
import {axiosInstance} from "@app/api";
import {useTranslation} from "react-i18next";

function useAxios<T>(axiosParams: AxiosRequestConfig): {
    data: T | undefined;
    isLoading: boolean;
    error: any;
    triggerRequest: (additionalRequestParams: AxiosRequestConfig) => void;
} {
    const [data, setData] = useState<T>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const cancelTokenSource = useRef<CancelTokenSource | null>(null);
    const { t } = useTranslation();

    const makeRequest = async (params: AxiosRequestConfig) => {
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel(t("layers.canceledDue"));
        }

        // Yeni CancelToken oluştur
        cancelTokenSource.current = createCancelToken();

        try {
            setIsLoading(true);
            const result = await axiosInstance.request<T>({...params, cancelToken: cancelTokenSource.current.token});
            setData(result.data);
        } catch (error) {
            if (isCancel(error)) {
                console.log(t("hooks.requestCanceled"), error.message);
            } else {
                setError(error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const triggerRequest = (additionalParams: AxiosRequestConfig) => {
        makeRequest({...axiosParams, ...additionalParams});
    };

    useEffect(() => {
        return () => {
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel(t("hooks.unmountedComponent"));
            }
        };
    }, []);

    return {
        data,
        isLoading,
        error,
        triggerRequest,
    };
}

export default useAxios;
