import {useAppDispatch, useAppSelector} from "@app/hooks";
import {
    setDestinations,
    setFlags,
    setNavigationalStatuses,
    setSpeeds,
    setVesselTypes
} from "@store/slices/SelectedFilters/slice";
import Button from "@components/common/Button/Button";


interface SelectedFields {
    flags: string[];
    vesselTypes: string[];
    destinations: string[];
    speeds: string[];
    navigationalStatuses: string[];
}

const SelectedFiltersWidget: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedFields = useAppSelector((state) => state.selectedFilters);
    const mergeDestinations = useAppSelector((state) => state.mergeDestinations);

    // Mapping field names to their corresponding action creators
    const actionMap = {
        flags: setFlags,
        vesselTypes: setVesselTypes,
        destinations: setDestinations,
        speeds: setSpeeds,
        navigationalStatuses: setNavigationalStatuses,
    };

    const destinationFieldExtractor = (d: string): string[] => {
        // Find the destination with the matching key.name
        const destination = mergeDestinations.destinations.find(destination => destination.key.name === d);

        // If a matching destination is found, return its values; otherwise, return an empty array
        return destination ? Array.from(destination.key.values) : [];
    };

    // Function to handle filter icon click
    const toggleFilter = (field: string, value: string | number,selectedFieldsExtractor?:  ((d: any) => any) | undefined) => {
        // Get the current filter values for the field
        let copyItems = [...selectedFields[field]] || [];

        const valuesToToggle = selectedFieldsExtractor ? selectedFieldsExtractor(value) : [value];

        // Iterate through valuesToToggle to check their existence
        valuesToToggle.forEach((val) => {
            const valueExists = copyItems.includes(val);

            // If the value exists, remove it from the copyItems
            if (valueExists) {
                copyItems = copyItems.filter(item => item !== val);
            } else {
                // If the value does not exist, add it to the copyItems
                copyItems.push(val);
            }
        });
        console.log("copyItems",copyItems)

        const actionCreator = actionMap[field]
        dispatch(
            actionCreator(copyItems)
        );
    };

    // Flatten the filters into a list of objects with category and value for easy mapping
    const flattenFilters = (filters: SelectedFields) => {
        let flattened: { category: string; value: string }[] = [];
        Object.entries(filters).forEach(([category, values]) => {
            if (Array.isArray(values)) { // Type check to ensure values is an array
                values.forEach(value => {
                    if(category != "destinations" || (category == "destinations" && mergeDestinations.destinations.find(destination => destination.key.name === value))){
                        flattened.push({ category, value });
                    }
                });
            }
        });
        return flattened;
    };

    const flattenedFilters = flattenFilters(selectedFields);

    return (
        <div className="selected-filters-widget" style={{ display: 'flex', flexWrap: 'wrap' }}>
            {flattenedFilters.map((filter, index) => (
                <div key={index} className="filter-tag" style={{ marginRight: '5px' }}>
                    <Button
                        size="small"
                        onClick={() => toggleFilter(filter.category, filter.value, filter.category === "destinations" ? destinationFieldExtractor : undefined)}
                        style={{ marginBottom: '5px' }}
                    >
                        {`${filter.category}: ${filter.value}`}
                    </Button>
                </div>
            ))}
        </div>
    );
};

export default SelectedFiltersWidget;