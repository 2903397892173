import {Alert, Button, Checkbox, Form, Input, Row, Typography} from 'antd';
import {useAuth} from "@app/context/AuthContext";
import * as S from './SignInPage.style';
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {APPLICATION_PATHS} from "@app/routes";
import {useTranslation} from "react-i18next";

export default function SignInPage() {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const {signin} = useAuth();
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (values: { email: string; password: string, rememberMe: boolean }) => {
        try {
            await signin(values.email, values.password, values.rememberMe)
            setError(null);
        } catch (error) {
            setError(t("pages.login.failLogin"));
        }
    };

    return (
        <S.PageContainerRow justify="center">
            <S.PageContainerColumn xs={16} sm={12} lg={8}>
                <Typography.Title level={2} style={{textAlign: 'center'}}>
                    Sign In
                </Typography.Title>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{email: '', password: ''}}
                >
                    <Form.Item
                        name="email"
                        rules={[{required: true, message: t("pages.login.inpEmail"), type: 'email'}]}
                    >
                        <Input type="email" placeholder={t("pages.login.email")}/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: t("pages.login.inpPass")}]}
                    >
                        <Input.Password placeholder={t("pages.login.pass")}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            Sign In
                        </Button>
                    </Form.Item>
                    <Form.Item name="rememberMe" valuePropName="checked">
                        <Row>
                            <Checkbox>Remember me</Checkbox>
                            <Link to={APPLICATION_PATHS.FORGOT_PASSWORD_PATH} style={{marginLeft: 'auto'}}>
                                Forgot password?
                            </Link>
                        </Row>
                    </Form.Item>
                    {error && <Alert banner message={error} type="error"/>}
                    <Form.Item style={{textAlign: 'center'}}>
                        <Typography.Text>
                            Don't have an account?{' '}
                            <Link to={APPLICATION_PATHS.SIGN_UP_PATH}>Sign Up</Link>
                        </Typography.Text>
                    </Form.Item>
                </Form>
            </S.PageContainerColumn>
        </S.PageContainerRow>
    );
}
