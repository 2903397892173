import React from 'react';
import { Menu } from 'antd';
import * as S from './CustomMenu.style';
import {useTranslation} from "react-i18next";

interface CustomMenuProps {
    selectedKey: string;
    onSelect: (key: string) => void;
}

const CustomMenu: React.FC<CustomMenuProps> = ({ selectedKey, onSelect }) => {
    const { t } = useTranslation();
    return (
        <S.CustomMenu mode="inline" selectedKeys={[selectedKey]} onSelect={({ key }) => onSelect(key)}>
            <Menu.Item key="overview">{t("pages.account.overview.accountOver")}</Menu.Item>
            <Menu.Item key="my_fleets">{t("pages.account.fleetOverview.myFleets")}</Menu.Item>

            <Menu.Item key="roi">{t("pages.account.ROIList.regInt")}</Menu.Item>
        </S.CustomMenu>
    );
};

export default CustomMenu;
