import {
    AnalyticData,
    ChartType,
    Countries,
    SpeedFilter,
    TrackAnalyticQueryFields,
    NavigationalStatus,
    MergedAnalyticData
} from '@app/common';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AnalyticDataCard} from '../AnalyticDataCard';
import {Col, Row} from 'antd';
import SelectedFiltersWidget from "@pages/HomePage/components/AnalyticActiveFilters/AnalyticActiveFilters";


const AnalyticPanel: React.FC = () => {
    const {t} = useTranslation();

    const flagFieldExtractor = (d: string) => Object.keys(Countries)[Object.values(Countries).indexOf(d as Countries)];

    const StatusFieldExtractor = (navigation: typeof NavigationalStatus[keyof typeof NavigationalStatus]) => {
        return Object.keys(NavigationalStatus)[Object.values(NavigationalStatus).indexOf(navigation)];
    }; //Extractor for Navigation Status

    const speedFieldExtractor = (d: string) => {
        console.log("selected speed ", d);
        const speeds = d.split('-');
        const speedFilter: SpeedFilter = {
            gt: parseInt(speeds[0], 10),
            lt: isNaN(parseInt(speeds[1], 10)) ? undefined : parseInt(speeds[1], 10),
        };

        if (isNaN(parseInt(speeds[0], 10))) {
            delete speedFilter.gt;
        }

        if (isNaN(parseInt(speeds[1], 10))) {
            delete speedFilter.lt;
        }
        console.log(speedFilter);

        return speedFilter;
    };
    return (
        <>
            <Row> <SelectedFiltersWidget/> </Row>
            <Row gutter={[15, 15]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <AnalyticDataCard
                        title={t('pages.home.flag')}
                        chartType={ChartType.HORIZONTAL_BAR}
                        trackQueryField={TrackAnalyticQueryFields.FLAG}
                        yAxisExtractor={(d: string) => Countries[d as keyof typeof Countries]}
                        selectedFieldsExtractor={flagFieldExtractor}
                        fieldFilterName={"flags"}
                    />
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <AnalyticDataCard
                        title={t('pages.home.vesselType')}
                        chartType={ChartType.HORIZONTAL_BAR}
                        trackQueryField={TrackAnalyticQueryFields.VESSEL_TYPE}
                        fieldFilterName={"vesselTypes"}
                    />
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <AnalyticDataCard
                        title={t('pages.home.destination')}
                        chartType={ChartType.HORIZONTAL_BAR}
                        trackQueryField={TrackAnalyticQueryFields.DESTINATION}
                        fieldFilterName={"destinations"}
                    />
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <AnalyticDataCard
                        title={t('pages.home.speed')}
                        chartType={ChartType.HORIZONTAL_BAR}
                        trackQueryField={{"Speed": [{"lt": 5}, {"gt": 5, "lt": 10}, {"gt": 10}]}}
                        selectedFieldsExtractor={speedFieldExtractor}
                        sortDataEnabled={false}
                        fieldFilterName={"speeds"}
                    />
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <AnalyticDataCard
                        title={t('pages.home.navigationalStatus')}
                        chartType={ChartType.HORIZONTAL_BAR}
                        trackQueryField={TrackAnalyticQueryFields.NAVIGATION}
                        yAxisExtractor={(d: string) => NavigationalStatus[d]}
                        selectedFieldsExtractor={StatusFieldExtractor}
                        fieldFilterName={"navigationalStatuses"}
                    />
                </Col>


            </Row>
        </>
    );
};

export default AnalyticPanel;