import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationTR from './locales/tr/translation.json';
import translationDE from './locales/de/translation.json';

const resources = {
  en: { translation: translationEN },
  tr: { translation: translationTR },
  de: { translation: translationDE },
};

// Check `localStorage` for the user's saved language, or fallback to 'en'
const storedLanguage = localStorage.getItem('lng') || 'en';

i18n.use(initReactI18next).init({
  resources,
  lng: storedLanguage, // Use `storedLanguage` here
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
});

export default i18n;
