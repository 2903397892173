import { AppLogo, AppLogoTitle } from '@components/index';
import { Row, Col } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { APPLICATION_PATHS } from '@app/routes';
import { AccountDropdown } from "@components/layout/Header/AccountDropdown";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';

// Styled component for the Tracks Button with a small margin
const TracksButton = styled.button`
    background-color: #0056b3;
    height: 30px;
    color: #ffffff;
    border: none;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 4px 8px;
    border-radius: 6px;

    &:hover {
        background-color: #004494;
    }

    margin-left: 48px; /* Small margin to separate the button from the title */
`;

const ExpandedHeader: React.FC = () => {
    const navigate = useNavigate();

    const handleNavigateTrackTable = () => {
        navigate(APPLICATION_PATHS.TRACK_TABLE_PATH);
    };

    return (
        <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            <Col>
                <Link to={APPLICATION_PATHS.APP_PATH}>
                    <Row align="middle">
                        <AppLogo width="25px" height="25px" margin="0 0.625rem 0 0" />
                        <AppLogoTitle title="Maritics" />
                    </Row>
                </Link>
            </Col>

            {/* Tracks Table Button close to the logo/title */}
            <Col>
                <TracksButton onClick={handleNavigateTrackTable}>
                    Tracks Table
                </TracksButton>
            </Col>

            {/* Account Dropdown positioned at the far right */}
            <Col style={{ marginLeft: 'auto' }}>
                <AccountDropdown />
            </Col>
        </Row>
    );
};

export default ExpandedHeader;
