import React, { useState } from 'react';
import { Menu, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { APPLICATION_PATHS } from '@app/routes';
import styled from 'styled-components';
import { lightColorsTheme } from '@styles/themes/light/LightTheme';
import {Icon} from "@iconify/react";


const TriggerButton = styled(Button)`
    color: ${lightColorsTheme.primaryColor};
    border: none;
    margin-bottom: 1px;
`;

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
`;

const InlineMenu: React.FC = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const navigate = useNavigate();

    const handleTracksTableNavigate = () => {
        navigate(APPLICATION_PATHS.TRACK_TABLE_PATH);
        setMenuVisible(false);
    };


    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    return (
        <MenuContainer>
            <TriggerButton icon={<MenuOutlined />} onClick={toggleMenu} />

            {menuVisible && (
                <Menu
                    mode="horizontal"
                    style={{
                        border: 'none',
                        background: 'transparent',
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Menu.Item
                        key="tracks-table"
                        onClick={handleTracksTableNavigate}
                        style={{ padding: '4px 8px' }}
                    >
                        <Icon icon="mingcute:ship-fill" height={23}  />
                    </Menu.Item>

                </Menu>
            )}
        </MenuContainer>
    );
};

export default InlineMenu;
