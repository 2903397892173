import React from 'react';
import { Modal, Input } from 'antd';
import {useTranslation} from "react-i18next";

interface FleetAddModalProps {
    isVisible: boolean;
    onOk: () => void;
    onCancel: () => void;
    newFleetName: string;
    newFleetColor: string;
    onFleetNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onColorChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FleetAddModal: React.FC<FleetAddModalProps> = ({
                                                         isVisible,
                                                         onOk,
                                                         onCancel,
                                                         newFleetName,
                                                         newFleetColor,
                                                         onFleetNameChange,
                                                         onColorChange
                                                     }) => {
    const { t } = useTranslation();
    return (
        <Modal
            title={t("pages.account.fleetAdd.addNewFleet")}
            visible={isVisible}
            onOk={onOk}
            onCancel={onCancel}
            okText={t("pages.account.fleetAdd.okText")}
            cancelText={t("common.cancel")}
        >
            <Input
                placeholder={t("pages.account.fleetAdd.enterFleetName")}
                value={newFleetName}
                onChange={onFleetNameChange}
            />
            <div style={{ marginTop: '10px' }}>
                <label htmlFor="colorPicker">Choose color:</label>
                <input
                    id="colorPicker"
                    type="color"
                    value={newFleetColor}
                    onChange={onColorChange}
                    style={{ marginLeft: '10px' }}
                />
            </div>
        </Modal>
    );
};

export default FleetAddModal;
