import selectedMapElementIcon from '@assets/images/selectedMapElementIcon.svg';
import {BASE_COLORS, IMapSettings, Track} from '@app/common';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { setSelectedMapTrackId } from '@store/slices/Tracks/slice';
import { getVesselTypeColor } from '@app/utils';
import L from 'leaflet';
import 'leaflet-tracksymbol';
import React, { useEffect, useRef, useState } from 'react';

import {useTranslation} from "react-i18next";
import { useMap } from 'react-leaflet';
import { useAuth } from "@app/context/AuthContext";
import { fetchFleetsByEmail } from "@store/slices/UserContent/thunks";
import { Fleet } from "@app/common";
import {setAnomaliesOnMap} from "@store/slices/AnomalyDetection/slice";
const LeafletTrackSymbolLayer: React.FC = () => {
  const {t} = useTranslation();
  const map = useMap();
  const dispatch = useAppDispatch();
  const anomalyClusters: string[] = useAppSelector((state) => state.anomalyDetection.data?.clusters || []);



  const redrawing = useRef<boolean>(false);
  const tracks: { [key: string]: Track } | null = useAppSelector((state) => state.tracks.mapTracks.data);
  const selectedMapTrackId: string | undefined = useAppSelector((state) => state.tracks.mapTracks.selectedTrackId);
  const mapTracksHistory: { [key: string]: Track[] } | null = useAppSelector(
      (state) => state.tracks.mapTrackHistories.data,
  );
  const { currentUser } = useAuth();
  const userEmail = currentUser?.email ? currentUser.email : "";
  const vesselMmsiList = useAppSelector((state) => state.vessel.vesselMmsiList);

  const [colorMmsiDict, setColorMmsiDict] = useState<{ [color: string]: string[] }>({});
  const [isFleetMode, setIsFleetMode] = useState<boolean>(false);

  const mapSettings: IMapSettings = useAppSelector((state) => state.mapSettings);

  useEffect(() => {
    if (vesselMmsiList.length > 0) {
      dispatch(fetchFleetsByEmail({ email: userEmail })).unwrap()
          .then((data) => {
            const newColorMmsiDict: { [color: string]: string[] } = {};
            data.forEach((fleet: Fleet) => {
              if (!newColorMmsiDict[fleet.color]) {
                newColorMmsiDict[fleet.color] = [];
              }

              fleet.vessels.forEach(vessel => {
                newColorMmsiDict[fleet.color].push(vessel.mmsi);
              });
            });

            setColorMmsiDict(newColorMmsiDict);
            console.log(newColorMmsiDict);
            setIsFleetMode(true);
          })
          .catch((error) => {
            console.error(t("components.layers.errorFetchingFleet"), error);
          });
    }
  }, [vesselMmsiList]);

  const assignClickHandlerToTrack = (trackMarker: any) => {
    const track: Track | undefined = trackMarker?.options?.track;

    trackMarker.on('click', () => {
      if (track && track.mmsi === selectedMapTrackId) {
        dispatch(setSelectedMapTrackId(undefined));
      } else if (track) {
        dispatch(setSelectedMapTrackId(track.mmsi));
      }
    });
  };
  const [anomalyCircles, setAnomalyCircles] = useState<L.CircleMarker[]>([]);
  useEffect(() => {
    const newAnomalyCircles: L.CircleMarker[] = [];
    let trackMarkers: L.Layer[] = [];
    let selectedMarkerContainer: L.Layer;
    let selectedElement: L.ImageOverlay;

    const markTracksOnMap = async () => {
      const anomaliesOnMap: string[] = [];
      if (!tracks) return;

      for (const anomalyCircle of anomalyCircles) {

        map.removeLayer(anomalyCircle);
      }

      map.createPane('anomalyCirclePane');
      map.getPane('anomalyCirclePane')!.style.zIndex = '650';
      for (const mmsi of Object.keys(tracks)) {
        const t: Track = tracks[mmsi];
        const isAnomaly = anomalyClusters.includes(t.mmsi);
        const strokeColor = isAnomaly ? BASE_COLORS.red : BASE_COLORS.black;
        const strokeWeight = isAnomaly ? 2.5 : 1.0;
        const latlng = L.latLng(t.location.lat, t.location.lon);
        const speed = t.speed;
        const course = (t.course * Math.PI) / 180.0;
        const heading = (t.course * Math.PI) / 180.0;
        let trackColor;

        if (vesselMmsiList.length>0) {

          for (const color of Object.keys(colorMmsiDict)) {
            if (colorMmsiDict[color].includes(t.mmsi)) {
              trackColor = color;
              break;
            }
          }
        }


        if (!trackColor) {
          trackColor = getVesselTypeColor(t.vesselType);
        }

        const opacity = selectedMapTrackId ? (t.mmsi === selectedMapTrackId ? 1.0 : 0.1) : 1.0;

        const trackMarker = (L as any).trackSymbol(latlng, {
          isTrackLayer: true,
          track: t,
          trackId: t.mmsi,
          fill: true,
          fillColor: trackColor,
          fillOpacity: opacity,
          stroke: true,
          color: strokeColor,
          opacity: 1.0,
          weight: strokeWeight,
          speed: speed,
          course: course,
          heading: heading,
        });

        // Display vessel names only if zoom level is greater than 9
        const zoomLevel = map.getZoom();
        if (mapSettings.showVesselNames && zoomLevel > 9) {
          trackMarker.bindTooltip(t.name, {
            permanent: true,
            sticky: false,
            direction: 'right',
            className: 'noArrow',
            offset: L.point(0, 0),
            opacity: opacity,
          });
        }

        // Access the tooltip directly to style it after it is created
        trackMarker.on('tooltipopen', (e) => {
          const tooltip = e.tooltip.getElement();

          if (tooltip) {
            // Apply styles directly to the tooltip element
            tooltip.style.backgroundColor = 'transparent';
            tooltip.style.border = 'transparent';
            tooltip.style.padding = '0';
            tooltip.style.color = 'black';
            tooltip.style.fontSize = '12px';
            tooltip.style.textAlign = 'center';
            tooltip.style.pointerEvents = 'none';
            tooltip.style.whiteSpace = 'none';
            tooltip.style.boxShadow = 'none'; // Remove shadow
            tooltip.style.webkitTextStroke = '1px black'; // Text border
            tooltip.style.textStroke = '2px black'; // Fallback for other browsers
            tooltip.style.textFillColor = 'black';
            tooltip.style.textShadow = `
                -1.5px -1.5px 0 white,  
                1.5px -1.5px 0 white,
                -1.5px 1.5px 0 white,
                1.5px 1.5px 0 white
            `;
          }
        });



        if (isAnomaly) {
          anomaliesOnMap.push(mmsi);
          const anomalyCircle = L.circleMarker(latlng, {
            radius: 15,
            color: BASE_COLORS.red,
            weight: 2.0,
            fillOpacity: 0,
            pane: 'anomalyCirclePane',
            interactive: false
          }).addTo(map);


          newAnomalyCircles.push(anomalyCircle);

        }
        setAnomalyCircles(newAnomalyCircles);



        if (selectedMapTrackId === trackMarker.options?.track?.mmsi) {
          const squareSize = 0.0035 * Math.pow(2, 13 - map.getZoom());

          const bounds = L.latLngBounds(
              [
                trackMarker.getBounds().getNorthEast().lat + squareSize,
                trackMarker.getBounds().getNorthEast().lng - squareSize,
              ],
              [
                trackMarker.getBounds().getSouthWest().lat - squareSize,
                trackMarker.getBounds().getSouthWest().lng + squareSize,
              ],
          );

          map.createPane('selectionImagePane');
          map.getPane('selectionImagePane')!.style.zIndex = '401';

          selectedElement = L.imageOverlay(selectedMapElementIcon, bounds, {
            pane: 'selectionImagePane',
          }).addTo(map);
        }

        trackMarker.addTo(map);

        assignClickHandlerToTrack(trackMarker);
        trackMarkers.push(trackMarker);
      }
      dispatch(setAnomaliesOnMap(anomaliesOnMap))
    };

    redrawing.current = false;
    markTracksOnMap();

    return () => {
      redrawing.current = true;
      trackMarkers.forEach((tm) => {
        map.removeLayer(tm);
      });
      if (selectedMarkerContainer) map.removeLayer(selectedMarkerContainer);
      if (selectedElement) map.removeLayer(selectedElement);
    };
  }, [map, tracks, mapSettings,selectedMapTrackId, mapTracksHistory, colorMmsiDict, isFleetMode]);

  return null;
};

export default LeafletTrackSymbolLayer;
