import React from 'react';
import { Modal, Input } from 'antd';
import {useTranslation} from "react-i18next";

interface EditFleetModalProps {
    visible: boolean;
    editedFleetName: string;
    setEditedFleetName: (name: string) => void;
    editedFleetColor: string;
    setEditedFleetColor: (color: string) => void;
    handleUpdateFleet: () => void;
    onCancel: () => void;
}

const EditFleetModal: React.FC<EditFleetModalProps> = ({
                                                           visible,
                                                           editedFleetName,
                                                           setEditedFleetName,
                                                           editedFleetColor,
                                                           setEditedFleetColor,
                                                           handleUpdateFleet,
                                                           onCancel
                                                       }) => {
    const { t } = useTranslation();
    return (
        <Modal
            title={t("pages.home.popover.editFleet.edit")}
            visible={visible}
            onOk={handleUpdateFleet}
            onCancel={onCancel}
            okText={t("common.save")}
            cancelText={t("common.cancel")}
        >
            <Input
                placeholder={t("pages.account.fleetOverview.fleetName")}
                value={editedFleetName}
                onChange={(e) => setEditedFleetName(e.target.value)}
            />
            <div style={{marginTop: '10px'}}>
                <label htmlFor="colorPicker">{t("pages.home.popover.addFleet.chooseColor")}</label>
                <input
                    id="colorPicker"
                    type="color"
                    value={editedFleetColor}
                    onChange={(e) => setEditedFleetColor(e.target.value)}
                    style={{marginLeft: '10px'}}
                />
            </div>
        </Modal>
    );
}

export default EditFleetModal;
