import {withAnimation, withLoading} from '@app/components';
import {HomePage} from '@app/pages';
import {APPLICATION_PATHS} from '@routes/index';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {SignInPage} from "@pages/SignInPage";
import {ForgotPasswordPage} from "@pages/ForgotPasswordPage";
import SignUpPage from "@pages/SignUpPage/SignUpPage";
import {AccountPage} from "@pages/AccountPage";
import TrackTablePage from "@pages/TrackTablePage/TrackTablePage";

const AppRouter: React.FC = () => {
    const Home = withLoading(withAnimation(HomePage, {height: '100%'}));

    const renderRoutes = () => (
        <Routes>
            <Route index path={APPLICATION_PATHS.ROOT_PATH} element={<Navigate to={APPLICATION_PATHS.APP_PATH}/>}/>
            <Route path={APPLICATION_PATHS.APP_PATH} element={  <Home/>}/>
            <Route path={APPLICATION_PATHS.SIGN_IN_PATH} element={<SignInPage/>}/>
            <Route path={APPLICATION_PATHS.FORGOT_PASSWORD_PATH} element={<ForgotPasswordPage/>}/>
            <Route path={APPLICATION_PATHS.SIGN_UP_PATH} element={<SignUpPage/>}/>
            <Route path={APPLICATION_PATHS.ACCOUNT_PATH} element={<AccountPage/>}/>
            <Route path={APPLICATION_PATHS.TRACK_TABLE_PATH} element={<TrackTablePage/>}/>


        </Routes>
    );

    return renderRoutes();
};

export default AppRouter;
