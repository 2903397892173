import { IMapSettings } from '@common/index';
import { createSlice } from '@reduxjs/toolkit';
import { fetchMapSettings, persistMapSettings } from '@services/index';

const initialState: IMapSettings = fetchMapSettings();

export const mapSettingsSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setMapSettings: (state, action) => {
      state.isSatelliteView = action.payload.isSatelliteView;
      state.isClustering = action.payload.isClustering;
      state.refreshRate = action.payload.refreshRate;
      state.showCoordinateGrids = action.payload.showCoordinateGrids;
      state.showHeatmap = action.payload.showHeatmap;
      state.showTracks = action.payload.showTracks;
      state.showVesselNames = action.payload.showVesselNames;
      state.showDestination = action.payload.showDestination;
      state.zoomOnSelect = action.payload.zoomOnSelect;
      state.isDataCurrent = action.payload.isDataCurrent;
    },
    setIsDataCurrent: (state, action) => {
      state.isDataCurrent = action.payload;
    },
  },
});

export const { setMapSettings, setIsDataCurrent } = mapSettingsSlice.actions;

export default mapSettingsSlice.reducer;
