import React, { useState, useEffect } from 'react';
import { List, Button, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { getROI } from '@store/slices/UserContent/thunks';
import {useAppDispatch} from '@app/hooks';
import {useTranslation} from "react-i18next";


interface ROIListProps {
    ROIs: any[];
    handleEditROI: (name: string) => void;
    handleDeleteROI: (name: string) => void;

}
import {useAuth} from "@app/context/AuthContext";


const ROIList: React.FC<ROIListProps> = ({ ROIs, handleEditROI, handleDeleteROI}) => {
    const { t } = useTranslation();
    const [sortedROIs, setSortedROIs] = useState<any[]>([]);
    const [sortOrder] = useState<string>('A-Z'); // Default sorting order
    const {currentUser} = useAuth();
    const userEmail = currentUser?.email ? currentUser.email : "";
    const dispatch = useAppDispatch();




    useEffect(() => {
        if(ROIs){
        sortROIs(sortOrder)}
    }, [sortOrder, ROIs]);

    const sortROIs = (order: string) => {
        const sortedList = [...ROIs];

        switch (order) {
            case 'A-Z':
                sortedList.sort((a, b) => a.name.localeCompare(b.name));
                break;

            default:
                break;
        }

        setSortedROIs(sortedList);
    };
    const handleROINameClicked = async (name:string) => {
        dispatch(getROI({email: userEmail, roiName: name }))
            .unwrap()
            .catch(error => {
                console.error(t("pages.account.ROIList.errorAddROI"), error);
            });
    }




    return (
        <div>
            <List
                header={<div>{t("pages.account.ROIList.regInt")}</div>}
                bordered
                dataSource={sortedROIs}
                renderItem={(item) => (
                    <List.Item>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: 20,
                                        height: 20,
                                        borderRadius: '50%',
                                        backgroundColor: item.color,
                                        marginRight: 8,
                                    }}
                                ></div>
                                <Button
                                    type="link"
                                    onClick={() => handleROINameClicked(item.name)}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '200px',
                                    }}
                                >
                                    {item.name}
                                </Button>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <Tooltip title={t("pages.account.ROIList.editROI")}>
                                    <Button
                                        type="text"
                                        icon={<EditOutlined style={{ color: 'blue' }} />}
                                        onClick={() => handleEditROI(item.name)}
                                    />
                                </Tooltip>
                                <Tooltip title={t("pages.account.ROIList.deleteROI")}>
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                                        onClick={() => handleDeleteROI(item.name)}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </List.Item>
                )}
                style={{ marginTop: 16 }}
            />

        </div>
    );
};

export default ROIList;
