import { IMapSettings } from '@app/common';
import { BaseForm, Space, Switch } from '@app/components';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { persistMapSettings } from '@app/services';
import { setMapSettings } from '@app/store/slices/MapSettings/slice';
import { Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './MapSettingsPopover.style';
import { setSelectedMapTrackId } from '@app/store/slices/Tracks/slice';

const MapSettingsPopover: React.FC = () => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const mapSettings: IMapSettings = useAppSelector((state) => state.mapSettings);

  useEffect(() => {
    form.setFieldValue('isSatelliteView', mapSettings.isSatelliteView);
    form.setFieldValue('showTracks', mapSettings.showTracks);
    form.setFieldValue('showHeatmap', mapSettings.showHeatmap);
    form.setFieldValue('showVesselNames', mapSettings.showVesselNames);
    form.setFieldValue('showCoordinateGrids', mapSettings.showCoordinateGrids);
    form.setFieldValue('zoomOnSelect', mapSettings.zoomOnSelect);
    form.setFieldValue('showDestination', mapSettings.showDestination);
  }, [mapSettings]);

  const onFormValuesChangeHandler = (e: any) => {
    const adjustedSettings: IMapSettings = { ...mapSettings };

    if ('isSatelliteView' in e) {
      adjustedSettings.isSatelliteView = e.isSatelliteView;
    }

    if ('showHeatmap' in e) {
      adjustedSettings.showHeatmap = e.showHeatmap;
    }

    if ('showTracks' in e) {
      !e.showTracks && dispatch(setSelectedMapTrackId(undefined));
      adjustedSettings.showTracks = e.showTracks;

      // Only reset showDestination when showTracks is turned off
      if (!e.showTracks) {
        adjustedSettings.showDestination = false;
        form.setFieldValue('showDestination', false);
      }
    }

    if ('showVesselNames' in e) {
      adjustedSettings.showVesselNames = e.showVesselNames;
    }

    if ('showCoordinateGrids' in e) {
      adjustedSettings.showCoordinateGrids = e.showCoordinateGrids;
    }

    if ('zoomOnSelect' in e) {
      adjustedSettings.zoomOnSelect = e.zoomOnSelect;
    }

    if ('showDestination' in e) {
      adjustedSettings.showDestination = e.showDestination;
    }

    dispatch(setMapSettings(adjustedSettings));
    persistMapSettings(adjustedSettings);
  };

  return (
    <S.PopoverCard padding={[25, 25]}>
      <BaseForm name="mapSettingsForm" form={form} onValuesChange={onFormValuesChangeHandler}>
        <Space direction="vertical" size="middle">

          <Row justify="space-between" align="middle">
            <S.SettingsTitle>{t('pages.home.isSatelliteView')}</S.SettingsTitle>
            <BaseForm.Item noStyle name="isSatelliteView" valuePropName="checked">
              <Switch />
            </BaseForm.Item>
          </Row>

          <Row justify="space-between" align="middle">
            <S.SettingsTitle>{t('pages.home.showTracks')}</S.SettingsTitle>
            <BaseForm.Item noStyle name="showTracks" valuePropName="checked">
              <Switch />
            </BaseForm.Item>
          </Row>

          <Row justify="space-between" align="middle">
            <S.SettingsTitle>{t('pages.home.showHeatmap')}</S.SettingsTitle>
            <BaseForm.Item noStyle name="showHeatmap" valuePropName="checked">
              <Switch />
            </BaseForm.Item>
          </Row>

          <Row justify="space-between" align="middle">
            <S.SettingsTitle>{t('pages.home.showVesselNames')}</S.SettingsTitle>
            <BaseForm.Item noStyle name="showVesselNames" valuePropName="checked">
              <Switch />
            </BaseForm.Item>
          </Row>

          <Row justify="space-between" align="middle">
            <S.SettingsTitle>{t('pages.home.showCoordinateGrids')}</S.SettingsTitle>
            <BaseForm.Item noStyle name="showCoordinateGrids" valuePropName="checked">
              <Switch />
            </BaseForm.Item>
          </Row>

          <Row justify="space-between" align="middle">
            <S.SettingsTitle>{t('pages.home.zoomOnSelect')}</S.SettingsTitle>
            <BaseForm.Item noStyle name="zoomOnSelect" valuePropName="checked">
              <Switch />
            </BaseForm.Item>
          </Row>

          <Row justify="space-between" align="middle">
            <S.SettingsTitle>{t('pages.home.showDestination')}</S.SettingsTitle>
            <BaseForm.Item noStyle name="showDestination" valuePropName="checked">
              <Switch disabled={!mapSettings.showTracks} />
            </BaseForm.Item>
          </Row>
        </Space>
      </BaseForm>
    </S.PopoverCard>
  );
};

export default MapSettingsPopover;
