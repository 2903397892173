import React, { useEffect, useRef, useState } from 'react';
import * as S from '../AccountPage/FleetView/FleetView.style';
import { Button, Tooltip, Card, Row, Col, Pagination, Input, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { fetchVesselsData } from '@store/slices/TrackTableVessels/thunks';
import moment from 'moment';
import AddToFleetModal from "@pages/TrackTablePage/AddToFleetModal/AddToFleetModal";
import {Track} from "@app/common";
import { Countries, IMapFilters } from '@app/common';
import {fetchFleetsByEmail} from "@store/slices/UserContent/thunks";
import {useAuth} from "@app/context/AuthContext";
import {message} from "antd";
import {getFleetByName} from "@store/slices/UserContent/thunks";
import {addVesselToFleet} from "@store/slices/UserContent/thunks";
import {ColumnType} from "antd/es/table";
import { FilterOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";
const { Option } = Select;
interface ICategoricalPayload {
    Flag?: Countries[];
    VesselType?: string[]
}


interface ISpeedPayload {
    min: number;
    max: number;
}
interface IPaginationPayload {
    from: number;
    size: number;
}
interface ISortFilters {
    [key: string]: 'asc' | 'desc';
}

type FilterPayload = [ICategoricalPayload] | ISpeedPayload | [ISortingPayload] | IPaginationPayload | [IRegexpPayload];


interface ISortingPayload {
    field: string;
    order: 'asc' | 'desc';
}
interface IRegexpPayload {
    [key: string]: string;
}



interface IFilter {
    type: 'CATEGORICAL' | 'SPEED' | 'SORT' | 'PAGINATION' | 'REGEXP';
    payload: FilterPayload ;
}



interface IFilters {
    filters: IFilter[];
}

const vesselTypeOptions = [
    "Auxiliary",
    "Cargo",
    "Fishing",
    "High Speed Craft",
    "Pleasure Craft",
    "Passenger ship",
    "Tanker",
    "WIG",
    "Unknown"

];
const TrackTablePage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [trackDetails, setTrackDetails] = useState<any[]>([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
    const dispatch = useAppDispatch();
    const mapFilters: IMapFilters = useAppSelector((state) => state.filters);
    const IMAGE_SERVER_PATH = process.env.REACT_APP_IMAGE_SERVER_PATH;
    const [speedFilter, setSpeedFilter] = useState({ gt: -1, lt: 100000 });
    const [flagFilter, setFlagFilter] = useState<Countries[]>([]);
    const [typeFilter, setTypeFilter] = useState<string[] | null>();
    const [searchFilter, setSearchFilter] = useState<any | null>(null);
    const [nameFilter, setNameFilter] = useState<any>('');
    const [mmsiFilter, setMmsiFilter] = useState('');
    const [imoFilter, setImoFilter] = useState('');
    const [regexpFilters, setRegexpFilters] = useState<IFilter[]>([]);
    const { t } = useTranslation();
    const [trackCount, setTrackCount] = useState(0);
    const [sortFilters, setSortFilters] = useState<ISortFilters | null>(null);
    const [showFilters, setShowFilters] = useState(false);
    const [selectedVessel, setSelectedVessel] = useState<Track | null>(null);
    const {currentUser} = useAuth();
    const userEmail = currentUser?.email ? currentUser.email : "";
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fleets, setFleets] = useState<any[]>([]);
    const [selectedFleet, setSelectedFleet] = useState<string | null>(null);
    const translatedVesselTypes = vesselTypeOptions.map(option =>
        t(`home.pages.trackTable.vesselTypes.${option}`)
    );
    const toggleFilterVisibility = () => {
        setShowFilters(!showFilters);
    };
    const handleTypeFilterChange = (typeFilter) => {
        setTypeFilter(typeFilter)

    }
    const handleSpeedFilterChange = (newFilter) => {

        setSpeedFilter(newFilter);

    };

    const handleCountryFilterChange = (selectedCountry) => {
        setFlagFilter(selectedCountry);
    };
    const handleSearchFilterApply = (filter: any) => {
        setSearchFilter(filter);
    };

    const updateRegexpFilters = (key: string, value: string) => {
        setRegexpFilters((prevFilters) => {
            const updatedFilters = prevFilters.filter(filter => filter.payload[0][key] === undefined);

            if (value) {
                updatedFilters.push({
                    type: "REGEXP",
                    payload: [{ [key]: value }]
                });
            }
            return updatedFilters;
        });
    };

    const handleNameFilterChange = (name: string) => {
        updateRegexpFilters("Name", name);
    };

    const handleMmsiFilterChange = (mmsi: string) => {
        updateRegexpFilters("MMSI", mmsi);
    };

    const handleImoFilterChange = (imo: string) => {
        updateRegexpFilters("IMO", imo);
    };


    useEffect(() => {

        fetchTracks(pagination.current, pagination.pageSize, speedFilter, typeFilter, flagFilter, regexpFilters, searchFilter, sortFilters);
    }, [pagination, speedFilter, flagFilter, typeFilter, searchFilter, sortFilters, regexpFilters]);
    const formatLastUpdatedTime = (time: string | undefined) => {
        if (!time) return 'N/A';
        return moment(time).format('YYYY-MM-DD HH:mm:ss');
    };
    const formatPosition = (lat: number, lon: number) => {
        return (
            <div style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                <div>
                    <span style={{ fontWeight: 'bold' }}>{t("pages.trackTable.latitude")}: </span>{lat.toFixed(4)}
                </div>
                <div>
                    <span style={{ fontWeight: 'bold' }}>{t("pages.trackTable.longitude")}: </span>{lon.toFixed(4)}
                </div>
            </div>
        );
    };
    const getVesselFlag = (countryCode: string | undefined) => {
        const unknownFlagOutput = (
            <Tooltip title={t("pages.trackTable.unknown")}>
                <S.UnknownFlag />
            </Tooltip>
        );

        if (!countryCode) {
            return unknownFlagOutput;
        }

        const countryName = Countries[countryCode as keyof typeof Countries];

        return countryName ? (
            <Tooltip title={countryName}>
                <S.CountryFlag svg countryCode={countryCode} />
            </Tooltip>
        ) : (
            unknownFlagOutput
        );
    };
    const fetchTracks = (
        page = pagination.current,
        pageSize = pagination.pageSize,
        speedFilter = { gt: -1, lt: 100000 },
        typeFilter,
        flagFilter: Countries[] = [],
        regexpFilters: IFilter[] = [],
        searchFilter: any = null,
        sort: any | null = null
    ) => {

        const filters: IFilters = {
            filters: []
        };


        if (flagFilter.length > 0) {
            filters.filters.push({
                type: "CATEGORICAL",
                payload: [{ Flag: flagFilter }]
            });
        }

        if (typeFilter && typeFilter.length > 0) {
            filters.filters.push({
                type: "CATEGORICAL",
                payload: [{ VesselType: typeFilter }]
            });
        }


        if (speedFilter.gt !== -1 && speedFilter.lt !== 100000) {
            filters.filters.push({
                type: "SPEED",
                payload: { min: speedFilter.gt, max: speedFilter.lt }
            });
        }


        if (searchFilter) {
            filters.filters.push(searchFilter);
        }

        filters.filters.push(...regexpFilters);



        if (sort) {
            filters.filters.push({
                type: "SORT",
                payload: [sort]
            });
        }
        filters.filters.push({
            type: "PAGINATION",
            payload: { from: (page - 1) * pageSize, size: pageSize }
        });


        setLoading(true);
        dispatch(fetchVesselsData(filters))
            .unwrap()
            .then((data) => {
                const updatedTrackDetails = data.vessels_list.map((track) => ({
                    mmsi: track.MMSI || t("pages.trackTable.unknown"),
                    imo: track.IMO || t("pages.trackTable.unknown"),
                    name: track.Name || t("pages.trackTable.unknown"),
                    vesselType: track.VesselType || t("pages.trackTable.unknown"),
                    flag: track.Flag || t("pages.trackTable.unknown"),
                    lastUpdateTime: track['@timestamp'] || t("pages.trackTable.unknown"),
                    position: { lat: track.Latitude, lon: track.Longitude },
                    speed: track.Speed || 0,
                }));

                setTrackDetails(updatedTrackDetails);
                setTrackCount(data.total_count);
            })
            .catch((error) => {
                console.error('Error fetching vessel data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleAddToFleet = async (track: Track) => {
        setSelectedVessel(track);
        try {
            dispatch(fetchFleetsByEmail({ email: userEmail }))
                .unwrap()
                .then((data) => {
                    setFleets(data);
                    setIsModalVisible(true);
                });
        } catch (error) {
            console.error('Error fetching fleets:', error);
            message.error('Error fetching fleets');
        }
    };

    const handleAddVesselToFleet = async () => {

        if (selectedVessel && selectedFleet) {
            try {
                const fleetData = await dispatch(
                    getFleetByName({ email: userEmail, fleetName: selectedFleet })
                ).unwrap();
                const vesselExists = fleetData.vessels.some(
                    (vessel: { mmsi: string }) => vessel.mmsi === selectedVessel.mmsi
                );
                if (vesselExists) {
                    message.warning(t("pages.trackTable.vesselAlreadyAdded"));
                } else {
                    dispatch(
                        addVesselToFleet({
                            email: userEmail,
                            fleetName: selectedFleet,
                            vesselMmsi: selectedVessel.mmsi,
                        })
                    );
                    message.success(t("pages.trackTable.successfullyAdded"));
                }
            } catch (error) {
                console.error('Error adding vessel to fleet:', error);
                message.error(t("pages.trackTable.errorAdded"));
            }
        }
        setIsModalVisible(false);
    };

    const columns: ColumnType<any>[] = [
        {
            title: t("pages.trackTable.vesselImage"),
            key: 'vesselImage',
            render: (_, vessel) => (
                <img
                    src={`${IMAGE_SERVER_PATH}/vessel?mmsi=${vessel.mmsi}`}
                    alt="vessel"
                    style={{ width: '100px', height: 'auto', marginRight: '15px' }}
                />
            ),
        },
        {
            title: t("pages.trackTable.country"),
            dataIndex: 'flag',
            filterIcon: () => (
                <FilterOutlined style={{ color: flagFilter.length > 0 ? '#0056b3' : '#7d7d7d', fill: flagFilter.length > 0 ? '#0056b3' : '#7d7d7d' }} />
            ),
            render: (_, vessel) => getVesselFlag(vessel.flag),
            filterDropdown: ({ confirm }) => {
                const [selectedFlags, setSelectedFlags] = useState([]);

                const handleFlagChange = (values) => {
                    setSelectedFlags(values);

                };

                const handleFilter = () => {
                    setFlagFilter(selectedFlags)

                    confirm();

                };

                const countryOptions = Object.entries(Countries).map(([shortCode, longName]) => ({
                    value: shortCode,
                    label: longName,
                }));
                const handleClear = () => {
                    setSelectedFlags([]);
                    handleFlagFilterClear();
                    confirm();
                }

                return (
                    <div style={{ padding: 8, width: '200px' }}>
                        <Select
                            placeholder={t("pages.trackTable.selectCountry")}
                            mode="multiple"
                            showSearch
                            optionFilterProp="children"
                            value={selectedFlags}
                            onChange={handleFlagChange}
                            filterOption={(input, option) => {
                                const optionText = option?.children?.toString() || '';
                                return optionText.toLowerCase().includes(input.toLowerCase());
                            }}
                            style={{ width: '100%' }}
                        >
                            {countryOptions.map((country) => (
                                <Option key={country.value} value={country.value}>
                                    {country.label}
                                </Option>
                            ))}
                        </Select>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 8}}>
                            <Button
                                type="default"
                                onClick={handleClear}
                                style={{width: '48%'}}
                            >
                                {t("pages.trackTable.clear")}
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleFilter}
                                style={{width: '48%'}}
                            >
                                {t("pages.trackTable.filter")}
                            </Button>
                        </div>
                    </div>
                );
            },


        },
        {
            title: t("pages.trackTable.name"),
            dataIndex: 'name',
            key: 'name',
            render: (name) => name || t("pages.trackTable.unknown"),
            filterIcon: () => {

                const hasNameFilter = regexpFilters.some(filter => filter.payload[0].Name);
                return (
                    <FilterOutlined
                        style={{
                            color: hasNameFilter ? '#0056b3' : '#7d7d7d',
                            fill: hasNameFilter ? '#0056b3' : '#7d7d7d'
                        }}
                    />
                );
            },
            filterDropdown: ({ confirm }) => {
                const [nameToSearch, setNameToSearch] = useState('');



                const handleApplyFilter = () => {

                    if (nameToSearch) {

                        handleNameFilterChange(nameToSearch);
                        confirm();
                    }


                };


                const handleClear = () => {

                    setRegexpFilters(prevFilters =>
                        prevFilters.filter(filter => !filter.payload[0].Name)
                    );
                    setNameToSearch('');
                    confirm();
                };
                const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    setNameToSearch(e.target.value);
                }
                const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                        handleApplyFilter();
                    }
                };
                return (
                    <div style={{ padding: 8, width: '200px' }}>
                        <Row>
                            <Col span={24}>
                                <Input
                                    placeholder={t("pages.trackTable.searchByName")}
                                    value={nameToSearch}
                                    onChange={handleSearchValueChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </Col>
                        </Row>
                        <Row>

                            <Col span={12}>
                                <Button onClick={handleClear}>{t("pages.trackTable.clear")}</Button>
                            </Col>
                            <Col span={12}>
                                <Button type="primary" onClick={handleApplyFilter} style={{ marginRight: '10px' }}>
                                    {t("pages.trackTable.search")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );

            },
        },
        {
            title: t("pages.trackTable.type"),
            dataIndex: 'vesselType',
            key: 'vesselType',
            filterIcon: () => (
                <FilterOutlined
                    style={{
                        color: (typeFilter && typeFilter.length > 0) ? '#0056b3' : '#7d7d7d',
                        fill: (typeFilter && typeFilter.length > 0) ? '#0056b3' : '#7d7d7d'
                    }}
                />
            ),
            render: (type) => type || 'N/A',
            filterDropdown: ({confirm}) => {
                const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
                const [customType, setCustomType] = useState<string>('');
                const [dropdownVisible, setDropdownVisible] = useState(false);

                const handleTypeChange = (values) => {

                    setSelectedTypes(values);

                    setDropdownVisible(false);
                };

                const handleFilter = () => {
                    setTypeFilter(selectedTypes);


                    confirm();
                };

                const handleClear = () => {
                    setSelectedTypes([]);
                    setCustomType('');
                    handleTypeFilterClear();
                    confirm();
                };

                const handleAddCustomType = () => {
                    if (customType && !selectedTypes.includes(customType)) {
                        setSelectedTypes([...selectedTypes, customType]);
                        setCustomType('');
                    }
                };

                return (
                    <div style={{ padding: 8, width: '200px' }}>
                        <Select
                            placeholder={t("pages.trackTable.selectType")}
                            mode="multiple"
                            showSearch
                            optionFilterProp="children"
                            value={selectedTypes}
                            onChange={handleTypeChange}
                            autoClearSearchValue={true}
                            filterOption={(input, option) => {
                                const optionText = option?.children?.toString() || '';
                                return optionText.toLowerCase().includes(input.toLowerCase());
                            }}
                            style={{ width: '100%' }}
                        >
                            {translatedVesselTypes.map((type, index) => (
                                <Option key={index} value={vesselTypeOptions[index]}>
                                    {type}
                                </Option>
                            ))}
                        </Select>



                        <Input
                            placeholder={t("pages.trackTable.enterCustomType")}
                            value={customType}
                            onChange={(e) => setCustomType(e.target.value)}
                            onPressEnter={handleAddCustomType}
                            style={{ margin: '8px 0' }}
                        />
                        <Button
                            type="primary"
                            onClick={handleAddCustomType}
                            disabled={!customType}
                            style={{ width: '100%', marginBottom: 8 }}
                        >
                            {t("pages.trackTable.add")}
                        </Button>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                type="default"
                                onClick={handleClear}
                                style={{ width: '48%' }}
                            >
                                {t("pages.trackTable.clear")}
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleFilter}
                                style={{ width: '48%' }}
                            >
                                {t("pages.trackTable.filter")}
                            </Button>
                        </div>
                    </div>
                );
            },
        },


        {
            title: t("pages.trackTable.mmsi"),
            dataIndex: 'mmsi',
            key: 'mmsi',
            sorter: true,
            filterIcon: () => {

                const hasMMSIFilter = regexpFilters.some(filter => filter.payload[0].MMSI);
                return (
                    <FilterOutlined
                        style={{
                            color: hasMMSIFilter ? '#0056b3' : '#7d7d7d',
                            fill: hasMMSIFilter ? '#0056b3' : '#7d7d7d'
                        }}
                    />
                );
            },
            filterDropdown: ({ confirm }) => {
                const [mmsiToSearch, setMmsiToSearch] = useState('');



                const handleApplyFilter = () => {
                    if (mmsiToSearch) {
                        handleMmsiFilterChange(mmsiToSearch)
                        confirm();
                    }
                };


                const handleClear = () => {

                    setRegexpFilters(prevFilters =>
                        prevFilters.filter(filter => !filter.payload[0].MMSI)
                    );
                    setMmsiToSearch('');
                    confirm();
                };
                const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    setMmsiToSearch(e.target.value);
                }
                const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                        handleApplyFilter();
                    }
                };
                return (
                    <div style={{ padding: 8, width: '200px' }}>
                        <Row>
                            <Col span={24}>
                                <Input
                                    placeholder={t("pages.trackTable.searchByMMSI")}
                                    value={mmsiToSearch}
                                    onChange={handleSearchValueChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </Col>
                        </Row>
                        <Row>

                            <Col span={12}>
                                <Button onClick={handleClear}>{t("pages.trackTable.clear")}</Button>
                            </Col>
                            <Col span={12}>
                                <Button type="primary" onClick={handleApplyFilter} style={{ marginRight: '10px' }}>
                                    {t("pages.trackTable.search")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );

            },
        },
        {
            title: 'IMO',
            dataIndex: 'imo',
            key: 'imo',
            sorter: true,
            filterIcon: () => {

                const hasIMOFilter = regexpFilters.some(filter => filter.payload[0].IMO);
                return (
                    <FilterOutlined
                        style={{
                            color: hasIMOFilter ? '#0056b3' : '#7d7d7d',
                            fill: hasIMOFilter ? '#0056b3' : '#7d7d7d'
                        }}
                    />
                );
            },
            filterDropdown: ({ confirm }) => {
                const [imoToSearch, setImoToSearch] = useState('');



                const handleApplyFilter = () => {
                    if (imoToSearch) {
                        handleImoFilterChange(imoToSearch)
                        confirm();
                    }
                };


                const handleClear = () => {

                    setRegexpFilters(prevFilters =>
                        prevFilters.filter(filter => !filter.payload[0].IMO)
                    );
                    setImoToSearch('');
                    confirm();
                };
                const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    setImoToSearch(e.target.value);
                }
                const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                        handleApplyFilter();
                    }
                };
                return (
                    <div style={{ padding: 8, width: '200px' }}>
                        <Row>
                            <Col span={24}>
                                <Input
                                    placeholder={t("pages.trackTable.searchByImo")}
                                    value={imoToSearch}
                                    onChange={handleSearchValueChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </Col>
                        </Row>
                        <Row>

                            <Col span={12}>
                                <Button onClick={handleClear}>{t("pages.trackTable.clear")}</Button>
                            </Col>
                            <Col span={12}>
                                <Button type="primary" onClick={handleApplyFilter} style={{ marginRight: '10px' }}>
                                    {t("pages.trackTable.search")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );

            },
        },
        {
            title: t("pages.trackTable.speed"),
            dataIndex: 'speed',
            filterIcon: () => (
                <FilterOutlined
                    style={{
                        color: speedFilter && speedFilter.gt !== -1 && speedFilter.lt !== 100000 ? '#0056b3' : '#7d7d7d',
                        fill: speedFilter && speedFilter.gt !== -1 && speedFilter.lt !== 100000 ? '#0056b3' : '#7d7d7d'
                    }}
                />
            ),
            filterDropdown: ({confirm, clearFilters}) => {
                const [min, setMin] = useState('');
                const [max, setMax] = useState('');

                const handleFilter = () => {
                    const minValue = min === '' ? NaN : parseFloat(min);
                    const maxValue = max === '' ? NaN : parseFloat(max);

                    if (!isNaN(minValue) && isNaN(maxValue)) {

                        handleSpeedFilterChange({ gt: minValue, lt: 99999 });
                    } else if (isNaN(minValue) && !isNaN(maxValue)) {

                        handleSpeedFilterChange({ gt: -2, lt: maxValue });
                    } else {

                        handleSpeedFilterChange({ gt: isNaN(minValue) ? 0 : minValue, lt: isNaN(maxValue) ? Infinity : maxValue });
                    }

                    confirm();
                };

                const handleClear = () => {
                    setMin('');
                    setMax('');
                    handleSpeedFilterClear();
                    confirm();
                };

                return (
                    <div style={{padding: 8, width: '200px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 8}}>
                            <Input
                                placeholder="Min"
                                value={min}
                                onChange={(e) => setMin(e.target.value)}
                                style={{marginRight: 8, flex: 1}}
                            />
                            <Input
                                placeholder="Max"
                                value={max}
                                onChange={(e) => setMax(e.target.value)}
                                style={{flex: 1}}
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button
                                type="default"
                                onClick={handleClear}
                                style={{width: '48%'}}
                            >
                                {t("pages.trackTable.clear")}
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleFilter}
                                style={{width: '48%'}}
                            >
                                {t("pages.trackTable.filter")}
                            </Button>
                        </div>
                    </div>
                );
            },

            sorter: (a: { speed: number }, b: { speed: number }) => a.speed - b.speed,

            sortDirections: ['ascend', 'descend'],


        },
        {
            title: t("pages.trackTable.lastUpdated"),
            dataIndex: 'lastUpdateTime',
            key: 'lastUpdateTime',
            render: (lastUpdateTime) => formatLastUpdatedTime(lastUpdateTime),
            sorter: true,
        },
        {
            title: t("pages.trackTable.position"),
            dataIndex: 'position',
            key: 'position',
            render: (position) => formatPosition(position.lat, position.lon),
        },
        {
            title: t("pages.trackTable.addVesselToFleet"),
            key: 'actions',
            render: (_, vessel: Track) => (
                <Button
                    onClick={() => handleAddToFleet(vessel)}
                    icon={<PlusOutlined />}
                    style={{
                        border: '1px solid #d9d9d9', // Grey border
                        backgroundColor: '#ffffff', // White background
                        color: '#000000', // Black icon color
                        borderRadius: '4px', // Rounded edges for consistency
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px', // Compact padding for the icon button
                    }}
                    shape="circle" // Makes the button circular
                />
            ),
        },
    ];



    const handleTableChange = (pagination, filters, sorter) => {
        const sortFieldMapping = {
            imo: 'IMO',
            speed: 'Speed',
            mmsi: 'MMSI',
            lastUpdateTime: '@timestamp',
        };


        const rawSortField = sorter.field as string;
        const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';


        const sortField = sortFieldMapping[rawSortField] || rawSortField;


        const sortObject: ISortFilters = { [sortField]: sortOrder };
        setSortFilters(sortObject);




        setPagination({
            ...pagination,
            current: pagination.current,
        });
    };

    const handleSpeedFilterClear = () => {
        setSpeedFilter({ gt: -1, lt: 100000 });
    }
    const handleTypeFilterClear = () => {
        setTypeFilter([]);
    }
    const handleFlagFilterClear = () => {
        setFlagFilter([]);
    }
    const handleSearchClear = () => {
        setSearchFilter(null);
    }

    return (
        <div>


            <S.CustomTable
                dataSource={trackDetails}
                columns={columns}
                rowKey="mmsi"
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: trackCount,
                    onChange: (page, pageSize) => {
                        setPagination({ current: page, pageSize });

                    },
                }}
                onChange={handleTableChange}
                loading={loading}
                rowClassName="customRow"
            />



            <AddToFleetModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                fleets={fleets}
                handleAddVesselToFleet={handleAddVesselToFleet}
                setSelectedFleet={setSelectedFleet}

            />
        </div>
    );
};

export default TrackTablePage;
